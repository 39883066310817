import React from "react";
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {Button} from 'react-bootstrap';

import {OfferTile} from "../../../components/offers/offer-tile";
import {ExpandableChecklistItem, BUTTON_TYPES} from "./checklist-item";

import copy from './copy.json';


export class YourFinancingChecklistItem extends React.Component {

    static propTypes = {
        openReviewOffersModal: PropTypes.func.isRequired,
    };

    copy = copy.your_financing;

    getTitle = () => this.copy.title;
    getButtonType = () => BUTTON_TYPES.DONE;

    ReviewOffersButton = () => (
        <Button className="d-block ml-auto js-review-offers" variant="outline-secondary" onClick={this.props.openReviewOffersModal}>
            {this.copy.offer_box_button}
        </Button>
    );

    ExpandedView = inject('completeMyPaperworkStore')(
        observer(({completeMyPaperworkStore}) => {
            if (!completeMyPaperworkStore.hasSelectedOffer) {
                return null;
            }
            return (
                <div className="mt-3">
                    <OfferTile
                        withoutShadow
                        offer={completeMyPaperworkStore.selectedOffer}
                        customCTAComponent={<this.ReviewOffersButton/>}
                    />
                </div>
            );
        })
    );

    render() {
        return (
            <ExpandableChecklistItem
                getTitle={this.getTitle}
                getButtonType={this.getButtonType}
                ExpandedView={this.ExpandedView}
            />
        );
    }
}
