import React from 'react';
import PropTypes from "prop-types";

import {ExpandableChecklistItem, BUTTON_TYPES, ChecklistItem} from "./checklist-item";
import {VehicleConfigurationPreview} from "../../../components/vehicle-tile";

import copy from './copy.json';


export class VehicleChecklistItem extends React.Component {
    static propTypes = {
        vehicleConfiguration: PropTypes.object,
        expandable: PropTypes.bool,
    };

    copy = copy.selected_vehicle;

    getTitle = () => this.copy.title;
    getButtonType = () => BUTTON_TYPES.DONE;

    VehiclePreview = () => {
        return (
            <div className="mt-3">
                <VehicleConfigurationPreview
                    {...this.props.vehicleConfiguration}
                />
            </div>
        );
    };

    render() {
        return this.props.expandable ? (
            <ExpandableChecklistItem
                getTitle={this.getTitle}
                getButtonType={this.getButtonType}
                ExpandedView={this.VehiclePreview}
            />
        ) : (
            <ChecklistItem
                title={this.copy.title}
                buttonType={BUTTON_TYPES.DONE}
            />
        );
    }
}
