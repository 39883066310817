import React from "react";
import PropTypes from "prop-types";
import {CTAFooter} from "../../components/cta-footer";

/**
 * Upload CTA Footer for the stip verfication screens
 * Returns a CTAFooter configured to be:
 * - sticky by default
 * - narrower and non-sticky when not explicitly isSticky, including for Reveo partner experiences in iFrames
 */
export const UploadCTAFooter = ({onFilesSelected, secondaryHref, isSticky=true}) => {
    const ctaProps = {
        primary: {
            isFileUploadButton: true,
            single: true,
            onFilesSelected: onFilesSelected,
        },
        secondary: {
            label: "Back to Dealer Checklist",
            href: secondaryHref,
        },
    };

    if (!isSticky) {
        ctaProps.className = [{'w-450px': false}, "w-300px"];
    }

    return <CTAFooter {...ctaProps} isSticky={isSticky} />;
};

UploadCTAFooter.propTypes = {
    onFilesSelected: PropTypes.func,
    secondaryHref: PropTypes.string,
    isSticky: PropTypes.bool,
};
