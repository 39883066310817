import React from "react";
import {inject, observer} from 'mobx-react';
import {Container} from "react-bootstrap";
import copy from "./copy";
import { CTAFooter } from "../../../components/cta-footer";


export const Pending = inject('completeMyPaperworkStore', 'uxCopyStore')(
    observer(({completeMyPaperworkStore, uxCopyStore}) => {        
        let applicantType = completeMyPaperworkStore.applicantType;
        let title = uxCopyStore.getPendingCopy(`titles.${applicantType}`, copy.titles[applicantType]);
        let body = uxCopyStore.getPendingCopy(`body.${applicantType}`, copy.body[applicantType]);
        let subtext = uxCopyStore.getPendingCopy(`subtext.${applicantType}`, copy.subtext[applicantType]);
        let label = uxCopyStore.getPendingCopy(`button.label.${applicantType}`, copy.button.label[applicantType]);
        let continueButtonHref = completeMyPaperworkStore.readyToDecision ? completeMyPaperworkStore.getPaperworkUrl() : completeMyPaperworkStore.getPaperworkUrl('/additional-info', completeMyPaperworkStore.otherApplicantUuid);


        const ContinueButton = observer(() => {
            return (
                <CTAFooter
                    primary={{
                        oid: "pending-page-cta",
                        label: label,
                        href: continueButtonHref,
                    }}
                />
            )
        })

        return (
            <Container data-oid="pending-page" className="w-450px mw-100 mx-auto mt-5 text-center">
                <p data-oid="title" className="spark-text-24px-bold mb-3">
                    {title}
                </p>
                <p data-oid="body" className="spark-text-24px-bold mb-3">
                    {body}
                </p>
                <p data-oid="subtext" className="spark-text-16px">
                    {subtext}
                </p>
                <br/>
                <br/>
                <ContinueButton />
            </Container>
        );
    })
);
