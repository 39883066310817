import {action, computed, decorate, observable} from 'mobx';

import {
    validateIntegerString,
    validatePhone,
    validateSingleLine,
    validateSSN,
    validatePastMonthAndYear,
} from "../../utils/validation";
import {citizenshipStatuses} from "../../enums/citizenship_status";
import {formatPhoneNumber, formatPriceInput, formatSSN} from "../../utils/number-utils";

/**
 * @class AdditionalInfoStore
 *
 * Manages state of AdditionalInfo Component
 *
 * @property {object} additionalInfo - fields and their current values
 * @property {object} fieldStates - fields and their current state (e.g. UNVALIDATED, VALID or INVALID)
 *
 * @property {boolean} canContinue
 */
export class AdditionalInfoStore {
    static FIELD_CONFIGS = {
        citizenship_status: {
            defaultValue: null,
            validator: validateSingleLine,
            items: citizenshipStatuses.map(({id, name}) => ({key: id, label: name})),
            required: true,
        },
        ssn: {
            defaultValue: "",
            validator: validateSSN,
            formatter: formatSSN,
            required: false,
        },
        monthly_housing_payment: {
            defaultValue: "",
            validator: value => validateIntegerString(value.replace(/\D/g, '')),
            formatter: formatPriceInput,
            required: true,
        },
        job_title: {
            defaultValue: "",
            validator: validateSingleLine,
            required: true,
        },
        employer_name: {
            defaultValue: "",
            validator: validateSingleLine,
            required: true,
        },
        employer_phone: {
            defaultValue: "",
            validator: validatePhone,
            formatter: formatPhoneNumber,
            required: true,
        },
        employer_date: {
            defaultValue: null,
            validator: validatePastMonthAndYear,
            required: true,
        },
    };
    static FIELDS = Object.keys(AdditionalInfoStore.FIELD_CONFIGS);
    static FIELD_STATES = {
        VALID: 'valid',
        INVALID: 'invalid',
        UNVALIDATED: 'unvalidated',
    };

    // observables
    additionalInfo = null;
    fieldStates = null;

    constructor({hasSubmittedSocialSecurityNumber} = {}) {
        this.additionalInfo = {};
        this.fieldStates = {};
        AdditionalInfoStore.FIELD_CONFIGS.ssn = {
            ...AdditionalInfoStore.FIELD_CONFIGS.ssn,
            required: !hasSubmittedSocialSecurityNumber,
        }

        this.constructor.FIELDS.forEach(field => {
            // initialize all fields with default value
            this.additionalInfo[field] = this.constructor.FIELD_CONFIGS[field].defaultValue;
            // initialize all field states as unvalidated
            this.fieldStates[field] = this.constructor.FIELD_STATES.UNVALIDATED;
        });
    }

    /**
     * Validate field input.
     *  If there is no validator in the field config, return 'valid'.
     *
     * @param field {string}
     * @param value {string}
     * @returns {string} either 'valid' or 'invalid'
     */
    validateFieldInput(field, value) {
        const fieldValidator = this.constructor.FIELD_CONFIGS[field].validator;

        if (fieldValidator && !fieldValidator(value)) {
            return this.constructor.FIELD_STATES.INVALID;
        }

        return this.constructor.FIELD_STATES.VALID;
    }

    /**
     * Format field input if that field has a formatter in the field config.
     *  Otherwise just returns the value passed in.
     *
     * @param field {string}
     * @param value {string}
     * @returns {string} formatted input value.
     */
    formatFieldInput(field, value) {
        const fieldFormatter = this.constructor.FIELD_CONFIGS[field].formatter;

        if (!fieldFormatter) {
            return value;
        }

        return fieldFormatter(value);
    }

    /**
     * Update a single field in the additionalInfo object
     *
     * @param {string} field
     * @param {*} value
     * @param {boolean} [forceUpdateFieldState] - defaults to true
     */
    updateAdditionalInfoField(field, value, forceUpdateFieldState = true) {
        // ignore fields that are not valid additionalInfo keys
        if (this.isValidAdditionalInfoField(field)) {
            const fieldValue = this.formatFieldInput(field, value);
            const oldFieldState = this.getFieldState(field);
            const newFieldState = this.validateFieldInput(field, value);

            // update field value
            this.additionalInfo[field] = fieldValue;

            // update the field state when...
            //  - forceUpdateFieldState = true (on blur of input)
            //  - the field is valid (to make sure CTA is clickable even before blur)
            //  - the field was valid but is now invalid (to make sure CTA is not clickable if it was previously)
            const shouldUpdateFieldState = (
                forceUpdateFieldState ||
                (newFieldState === this.constructor.FIELD_STATES.VALID) ||
                (
                    (oldFieldState === this.constructor.FIELD_STATES.VALID) &&
                    (newFieldState === this.constructor.FIELD_STATES.INVALID)
                )
            );
            if (shouldUpdateFieldState) {
                this.fieldStates[field] = newFieldState;
            }
        }
        return this;
    }
    /**
     * Returns true if field is a valid key in the additionalInfo object
     */
    isValidAdditionalInfoField = field => this.constructor.FIELDS.includes(field);
    /**
     * Returns a field's items (as defined in class's FIELD_CONFIGS)
     */
    getFieldItems = field => this.constructor.FIELD_CONFIGS[field].items || null;
    /**
     * Returns a field's current state (e.g. UNVALIDATED, VALID or INVALID)
     */
    getFieldState = field => this.fieldStates[field];
    /**
     * Returns whether or not a field was validated and no errors were found
     */
    isFieldValid = field => this.getFieldState(field) === this.constructor.FIELD_STATES.VALID;
    /**
     * Returns whether or not a field was validated and errors were found
     */
    isFieldInvalid = field => this.getFieldState(field) === this.constructor.FIELD_STATES.INVALID;
    /**
     * Returns whether or not a field was validated
     */
    isFieldUnvalidated = field => this.getFieldState(field) === this.constructor.FIELD_STATES.UNVALIDATED;
    /**
     * Returns whether or not a field is required
     */
    isFieldRequired = field => this.constructor.FIELD_CONFIGS[field].required;
    /**
     * Computed value which determines if the user can continue
     * @returns {boolean}
     */
    get canContinue() {
        // returns true iff all required fields are valid
        return !this.constructor.FIELDS.find(field => this.isFieldRequired(field) && !this.isFieldValid(field));
    }
}

decorate(AdditionalInfoStore, {
    fieldStates: observable,
    additionalInfo: observable,

    canContinue: computed,

    updateAdditionalInfoField: action,
});
