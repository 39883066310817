import React from "react";
import {inject} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';

import {Page} from "./page";

// Stores
import {ApiStore} from "../stores/api-store";
import {CompleteMyPaperworkStore} from "../stores/complete-my-paperwork-store";

// Components
import {ReturnToServer} from "../components/return-to-server";
import {DealerChecklist} from "../complete-my-paperwork/dealer-checklist";
import {AdditionalInfo} from "../complete-my-paperwork/additional-info/additional-info";
import {Pending} from "../complete-my-paperwork/additional-info/pending-page/pending";
import {IncomeVerification} from "../complete-my-paperwork/verification/income-verification";
import {PhoneBillVerification} from "../complete-my-paperwork/verification/phone-bill-verification";
import {AddressVerification} from "../complete-my-paperwork/verification/address-verification";
import {PageTitles, withRideOctaneTitle} from "./octane-title";
import {SSNVerification} from "../complete-my-paperwork/verification/ssn-verification";
import {LicenseVerification} from "../complete-my-paperwork/verification/license-verification";
import {BrpGaPixel} from "../components/brp-ga-pixel";


// Complete My Paperwork
export function completeMyPaperwork() {

    const store = Page.createStore({
        isCompleteMyPaperwork: true,
        stores: {
            apiStore: new ApiStore(),
            completeMyPaperworkStore: new CompleteMyPaperworkStore(),
        },
    });

    const titleWords = [PageTitles.COMPLETE_MY_PAPERWORK_TITLE];
    const component = Page.createComponent({
        content: withRideOctaneTitle(<CompleteMyPaperworkContent/>, titleWords),
    });

    return {store, component};
}

// Complete My Paperwork for Applicant flow
export function applicantCompleteMyPaperwork() {

    const store = Page.createStore({
        isApplicantCompleteMyPaperwork: true,
        stores: {
            apiStore: new ApiStore(),
            completeMyPaperworkStore: new CompleteMyPaperworkStore(),
        },
    });

    const titleWords = [PageTitles.COMPLETE_MY_PAPERWORK_TITLE];
    const component = Page.createComponent({
        content: withRideOctaneTitle(<ApplicantCompleteMyPaperworkContent/>, titleWords),
    });

    return {store, component};
}

const CompleteMyPaperworkContent = inject('completeMyPaperworkStore')(
    function _CompleteMyPaperworkContent({completeMyPaperworkStore: cmpStore}) {
        return (
            <div>
                {/* invisible pixel BRP uses to track customers */}
                <BrpGaPixel
                    vehicle_configuration={cmpStore.vehicleConfiguration}
                />
                <Switch>
                    <Route exact path={cmpStore.getPaperworkUrl()} component={DealerChecklist}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/additional-info')} component={AdditionalInfo}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/income-verification')} component={IncomeVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/ssn-verification')} component={SSNVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/license-verification')} component={LicenseVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/phone-bill-verification')} component={PhoneBillVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/address-verification')} component={AddressVerification}/>
                    <Route component={ReturnToServer}/>
                </Switch>
            </div>
        );
    }
);

const ApplicantCompleteMyPaperworkContent = inject('completeMyPaperworkStore')(
    function _ApplicantCompleteMyPaperworkContent({completeMyPaperworkStore: cmpStore}) {
        return (
            <div>
                {/* invisible pixel BRP uses to track customers */}
                <BrpGaPixel
                    vehicle_configuration={cmpStore.vehicleConfiguration}
                />
                <Switch>
                    <Route exact path={cmpStore.getPaperworkUrl()} component={DealerChecklist}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/additional-info')} component={AdditionalInfo}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/additional-info/pending')} component={Pending}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/income-verification')} component={IncomeVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/ssn-verification')} component={SSNVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/license-verification')} component={LicenseVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/phone-bill-verification')} component={PhoneBillVerification}/>
                    <Route exact path={cmpStore.getPaperworkUrl('/address-verification')} component={AddressVerification}/>
                    <Route component={ReturnToServer}/>
                </Switch>
            </div>
        );
    }
);
