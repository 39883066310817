import get from 'lodash.get';
import {decorate, observable, computed, action} from 'mobx';

import {BaseStore} from "./base-store";
import {APPLICATION_TYPE} from "../enums/applications";
import {APPLICANT_TYPE} from "../enums/applicants";
import {pollOffers} from "../utils/offer-utils";
import {cleanNumberString, formatSSN} from "../utils/number-utils";
import {getTimeSinceDate} from "../utils/date-utils";
import {INCOME_INSTRUCTION_TYPES, VERIFICATION_STATUSES} from "../enums/verification";
import {DOCUMENT_TYPE, DOCUMENT_CATEGORY} from "../enums/documents";


/**
 * @class CompleteMyPaperworkStore
 * @inheritDoc
 *
 * Global store which
 *  - stores/updates the application's state (based on the applicationUuid)
 *
 * @property {string|null} applicationUuid
 * @property {object|null} vehicleConfiguration
 *  {
 *      uuid: {uuid},
 *      slug: {string},
 *      name: {string},
 *      msrp: {decimal},
 *      color: {
 *          color_url: {string},
 *          name: {string},
 *      },
 *      default_image: {string},
 *  }
 * @property {object|null} applicationOffers - available offers of the application
 *  {
 *      vehicle: {uuid},
 *      offers_expiration_date: {string},
 *      loan: {
 *          status: {string},
 *          result: {string},
 *          offers: [{object}, ...],
 *      },
 *  }
 * @property {object|null} applicationProgress - current state of the application (as returned from the api server)
 *  {
 *      did_submit_additional_info: {boolean},
 *      did_submit_income_verification: {boolean},
 *      did_submit_social_security_card: {boolean},
 *      did_submit_drivers_license: {boolean},
 *      did_submit_phone_bill: {boolean},
 *      did_submit_proof_of_address: {boolean},
 *      did_review_documents_to_bring: {boolean},
 *  }
 * @property {object|null} applicationSelectedOffer - currently selected offer
 *  {
 *      apr: {number}
 *      term: {number}
 *  }
 * @property {object|null} applicationIncomeVerification - current income verification status
 *  {
 *      type: string
 *      category: string
 *      status: string
 *      instruction_type: {string|null}
 *  }
 * @property {object|null} applicationSSNVerification - current ssn verification status
 *  {
 *      type: string
 *      category: string
 *      status: string
 *  }
 * @property {object|null} applicationLicenseVerification - current ssn verification status
 *  {
 *      type: string
 *      category: string
 *      status: string
 *  }
 * @property {object|null} applicationPhoneBillVerification - current phone bill verification status
 *  {
 *      type: string
 *      category: string
 *      status: string
 *  }
 * @property {object|null} applicationAddressVerification - current address verification status
 *  {
 *      type: string
 *      category: string
 *      status: string
 *  }
 */
export class CompleteMyPaperworkStore extends BaseStore {
    VEHICLE_CONFIGURATION_FIELDS = [
        'uuid',
        'slug',
        'name',
        'msrp',
        'default_image',
        'color',
    ];

    applicationUuid = null;
    applicationType = null;
    applicantUuid = null;
    otherApplicantUuid = null;
    applicantType = null;
    vehicleConfiguration = null;
    isApplicantCMPFlow = false;
    readyToDecision = null;

    // observables
    applicationOffers = null;
    applicationProgress = null;
    applicationSelectedOffer = null;
    applicationIncomeVerification = null;
    applicationSSNVerification = null;
    applicationLicenseVerification = null;
    applicationPhoneBillVerification = null;
    applicationAddressVerification = null;
    applicantDealerChecklistBtn = null;

    // applicant-centric observables
    applicantProgress = null;
    applicantIncomeVerification = null;
    applicantSSNVerification = null;
    applicantLicenseVerification = null;
    applicantPhoneBillVerification = null;
    applicantAddressVerification = null;
    otherApplicantProgress = null;
    otherApplicantIncomeVerification = null;
    otherApplicantSSNVerification = null;
    otherApplicantLicenseVerification = null;
    otherApplicantPhoneBillVerification = null;
    otherApplicantAddressVerification = null;

    /**
     * Returns a fieldName prefix for coapplicant flow dealer checklist's use
     */
    get prefix() {
        if (!this.isApplicantCMPFlow) return 'application';
        return this.applicantType === this.applicantDealerChecklistBtn? 'applicant' : 'otherApplicant';
    }

    /**
     * True if we were unable to get the state of the application from the api server
     */
    get isInvalidApplication() {
        if (!this.applicationOffers || !this.applicationProgress) {
            return true; // application was not found
        }

        return false;
    }

    /**
     * True if the application's offers have expired
     */
    get isExpiredApplication() {
        if (this.isInvalidApplication) {
            return false;
        }

        if (this.hasAvailableOffers) {
            return false;
        }

        return this.applicationOffers.loan.result === 'expired_application';
    }

    /**
     * True if the applicant has submitted their additional information
     */
    get hasSubmittedAdditionalInfo() {
        if (this.isInvalidApplication) {
            return false;
        }
        return this.applicationProgress.did_submit_additional_info;
    }

    /**
     * True if the applicant has submitted their social security number in SSN view
     */
    get hasSubmittedSocialSecurityNumber() {
        if (this.isInvalidApplication) {
            return false;
        }
        return this.applicationProgress.did_submit_social_security_number;
    }

    /**
     * True if the application has available offers
     */
    get hasAvailableOffers() {
        if (this.isInvalidApplication) {
            return false;
        }
        return this.loanOffers.length > 0;
    }

    /**
     * True  if the customer has selected an offer
     */
    get hasSelectedOffer() {
        if (this.isInvalidApplication) {
            return false;
        }
        return !!this.applicationSelectedOffer;
    }

    /**
     * True if the applicant has viewed docs to bring
     */
    get hasReviewedDocsToBring() {
        if (this.isInvalidApplication) {
            return false;
        }
        return this.applicationProgress.did_review_documents_to_bring;
    }

    /**
     * True if income verification is required and the user has submitted documents
     */
    get hasSubmittedIncomeVerification() {
        if (!this.incomeVerificationStatus || !this.isIncomeVerificationRequired) {
            return false;
        }
        return get(this, `${this.prefix}Progress.did_submit_income_verification`);
    }

    /**
     * True if income verification is required
     */
    get isIncomeVerificationRequired() {
        return (VERIFICATION_STATUSES.NOT_REQUIRED !== this.incomeVerificationStatus);
    }

    /**
     * True if income verification is not required or was accepted
     */
    get isIncomeVerificationAccepted() {
        if (!this.isIncomeVerificationRequired) {
            return true;
        }
        return (VERIFICATION_STATUSES.ACCEPTED === this.incomeVerificationStatus);
    }

    /**
     * True if income verification is not required, was accepted or was rejected
     */
    get isIncomeVerificationComplete() {
        if (this.isIncomeVerificationAccepted) {
            return true;
        }
        return (VERIFICATION_STATUSES.REJECTED === this.incomeVerificationStatus);
    }

    /**
     * True if ssn verification is required and the user has submitted documents
     */
    get hasSubmittedSSNVerification() {
        if (!this.ssnVerificationStatus || !this.isSSNVerificationRequired) {
            return false;
        }
        return get(this, `${this.prefix}Progress.did_submit_social_security_card`);
    }

    /**
     * True if ssn verification is required
     */
    get isSSNVerificationRequired() {
        return VERIFICATION_STATUSES.NOT_REQUIRED !== this.ssnVerificationStatus;
    }

    /**
     * True if ssn verification is not required or was accepted
     */
    get isSSNVerificationAccepted() {
        if (!this.isSSNVerificationRequired) {
            return true;
        }
        return VERIFICATION_STATUSES.ACCEPTED === this.ssnVerificationStatus;
    }

    /**
     * True if ssn verification is not required, was accepted or was rejected
     */
    get isSSNVerificationComplete() {
        if (this.isSSNVerificationAccepted) {
            return true;
        }
        return VERIFICATION_STATUSES.REJECTED === this.ssnVerificationStatus;
    }

    /**
     * True if license verification is required and the user has submitted documents
     */
    get hasSubmittedLicenseVerification() {
        if (!this.licenseVerificationStatus || !this.isLicenseVerificationRequired) {
            return false;
        }
        return get(this, `${this.prefix}Progress.did_submit_drivers_license`);
    }

    /**
     * True if license verification is required
     */
    get isLicenseVerificationRequired() {
        return VERIFICATION_STATUSES.NOT_REQUIRED !== this.licenseVerificationStatus;
    }

    /**
     * True if license verification is not required or was accepted
     */
    get isLicenseVerificationAccepted() {
        if (!this.isLicenseVerificationRequired) {
            return true;
        }
        return VERIFICATION_STATUSES.ACCEPTED === this.licenseVerificationStatus;
    }

    /**
     * True if license verification is not required, was accepted or was rejected
     */
    get isLicenseVerificationComplete() {
        if (this.isLicenseVerificationAccepted) {
            return true;
        }
        return VERIFICATION_STATUSES.REJECTED === this.licenseVerificationStatus;
    }

    /**
     * True if phone bill verification is required and the user has submitted documents
     */
    get hasSubmittedPhoneBillVerification() {
        if (!this.phoneBillVerificationStatus || !this.isPhoneBillVerificationRequired) {
            return false;
        }
        return get(this, `${this.prefix}Progress.did_submit_phone_bill`);
    }

    /**
     * True if phone bill verification is required
     */
    get isPhoneBillVerificationRequired() {
        return VERIFICATION_STATUSES.NOT_REQUIRED !== this.phoneBillVerificationStatus;
    }

    /**
     * True if phone bill verification is not required or was accepted
     */
    get isPhoneBillVerificationAccepted() {
        if (!this.isPhoneBillVerificationRequired) {
            return true;
        }
        return VERIFICATION_STATUSES.ACCEPTED === this.phoneBillVerificationStatus;
    }

    /**
     * True if phone bill verification is not required, was accepted or was rejected
     */
    get isPhoneBillVerificationComplete() {
        if (this.isPhoneBillVerificationAccepted) {
            return true;
        }
        return VERIFICATION_STATUSES.REJECTED === this.phoneBillVerificationStatus;
    }

    /**
     * True if proof of address verification is required and the user has submitted documents
     */
    get hasSubmittedAddressVerification() {
        if (!this.addressVerificationStatus || !this.isAddressVerificationRequired) {
            return false;
        }
        return get(this, `${this.prefix}Progress.did_submit_proof_of_address`);
    }

    /**
     * True if proof of address verification is required
     */
    get isAddressVerificationRequired() {
        return VERIFICATION_STATUSES.NOT_REQUIRED !== this.addressVerificationStatus;
    }

    /**
     * True if proof of address verification is not required or was accepted
     */
    get isAddressVerificationAccepted() {
        if (!this.isAddressVerificationRequired) {
            return true;
        }
        return VERIFICATION_STATUSES.ACCEPTED === this.addressVerificationStatus;
    }

    /**
     * True if proof of address verification is not required, was accepted or was rejected
     */
    get isAddressVerificationComplete() {
        if (this.isAddressVerificationAccepted) {
            return true;
        }
        return VERIFICATION_STATUSES.REJECTED === this.addressVerificationStatus;
    }

    get vehicleUuid() {
        if (!this.applicationOffers) {
            return null;
        }
        return this.applicationOffers.vehicle;
    }

    /**
     * Returns available loan offers
     *
     * @returns {array}
     *   [{
     *       offer_type: "loan"
     *       apr: {number}
     *       term: {number}
     *       vehicle_msrp: {number}
     *       loan_amount: {number}
     *       max_amount_financeable: {number}
     *       estimated_cash_down: {number}
     *       estimated_monthly_payment: {number}
     *   }, ...]
     */
    get loanOffers() {
        return get(this.applicationOffers, 'loan.offers', []);
    }

    /**
     * Returns the full selected offer for the application
     */
    get selectedOffer() {
        if (this.hasSelectedOffer && this.hasAvailableOffers) {
            return this.loanOffers.find(offer => (
                (this.applicationSelectedOffer.apr === offer.apr) &&
                (this.applicationSelectedOffer.term === offer.term)
            )) || null;
        }
        return null;
    }

    /**
     * Returns the current state of the customer's income verification review
     * @see /src/app/enums/verification.js
     */
    get incomeVerificationStatus() {
        if (!this.hasSubmittedAdditionalInfo) {
            return null;
        }
        return get(this, `${this.prefix}IncomeVerification.status`) || VERIFICATION_STATUSES.NOT_REQUIRED;
    }

    /**
     * Returns the instruction type for income verification (if it is required)
     * @see /src/app/enums/verification.js
     */
    get incomeInstructionType() {
        if (!this.incomeVerificationStatus || !this.isIncomeVerificationRequired) {
            return null;
        }
        return get(this, `${this.prefix}IncomeVerification.instruction_type`) || INCOME_INSTRUCTION_TYPES.DEFAULT;
    }

    /**
     * Returns the current state of the customer's ssn verification review
     * @see /src/app/enums/verification.js
     */
    get ssnVerificationStatus() {
        if (!this.hasSubmittedAdditionalInfo) {
            return null;
        }
        return get(this, `${this.prefix}SSNVerification.status`) || VERIFICATION_STATUSES.NOT_REQUIRED;
    }

    /**
     * Returns the current state of the customer's license verification review
     * @see /src/app/enums/verification.js
     */
    get licenseVerificationStatus() {
        if (!this.hasSubmittedAdditionalInfo) {
            return null;
        }
        return get(this, `${this.prefix}LicenseVerification.status`) || VERIFICATION_STATUSES.NOT_REQUIRED;
    }

    /**
     * Returns the current state of the customer's phone bill verification review
     * @see /src/app/enums/verification.js
     */
    get phoneBillVerificationStatus() {
        if (!this.hasSubmittedAdditionalInfo) {
            return null;
        }
        return get(this, `${this.prefix}PhoneBillVerification.status`) || VERIFICATION_STATUSES.NOT_REQUIRED;
    }

    /**
     * Returns the current state of the customer's address verification review
     * @see /src/app/enums/verification.js
     */
    get addressVerificationStatus() {
        if (!this.hasSubmittedAdditionalInfo) {
            return null;
        }
        return get(this, `${this.prefix}AddressVerification.status`) || VERIFICATION_STATUSES.NOT_REQUIRED;
    }

    /**
     * Returns bool as whether to render Dealer Approved view
     */
    get showApplicationDealerApprovedView() {
        return (
            !this.hasAvailableOffers &&
            this.applicationDealerResult === "approved" &&
            !!(this.partnerStore.partner && this.partnerStore.partner.enable_dealer_decision)
        );
    }

    /**
     * Returns bool as whether to render Dealer Declined view
     */
    get showApplicationDealerDeclinedView() {
        return (
            !this.hasAvailableOffers &&
            this.applicationDealerResult === "declined" &&
            !!(this.partnerStore.partner && this.partnerStore.partner.enable_dealer_decision)
        );
    }

    /**
     * Returns dealerResult to determine which Dealer Decision view is rendered
     */
    get applicationDealerResult() {
        if (this.hasAvailableOffers) {
            return null;
        }

        const dealerResult = get(this.applicationOffers, 'dealer_decision.dealer_result');
        if (!['approved', 'declined'].includes(dealerResult)) {
            return null;
        }
        return dealerResult;
    }

    /**
     * Returns whether or not to show Low Transparency flow
     */
    get showTransparentOffers() {
        return (this.partnerStore.partner && this.partnerStore.partner.show_transparent_offers) ?? true;
    }

    /**
     * Returns bool to show or not to show Driver License stip component in dealer checklist
     */
    get showDriversLicense() {
        return (this.partnerStore.partner && this.partnerStore.partner.show_drivers_license_request) ?? true;
    }

    /**
     * Returns trade in url from the dealership if available, otherwise it returns from the partner if available.
     */
     get tradeInUrl() {
        const dealership = this.dealershipStore.dealership;
        const partner = this.partnerStore.partner;

        if (!!dealership && !!dealership.trade_in_url) {
            return dealership.trade_in_url;
        } else if (!!partner && !!partner.trade_in_url) {
            return partner.trade_in_url;
        }
        return null;
    }

    /**
     * Returns finance url from the dealership if available, otherwise it returns from the partner if available.
     */
    get financeUrl() {
        const dealership = this.dealershipStore.dealership;
        const partner = this.partnerStore.partner;

        if (!!dealership && !!dealership.finance_url) {
            return dealership.finance_url;
        } else if (!!partner && !!partner.partner_finance_url) {
            return partner.partner_finance_url;
        }
        return null;
    }

    /**
     * Returns the corresponding verification submission endpoint for application and applicant flow
     */
    get submitVerificationEndpoint() {
        let endpoint = `applications/${this.applicationUuid}/verification`;
        if (this.isApplicantCMPFlow) {
            endpoint = `applicant/${this[`${this.prefix}Uuid`]}/verification`;
        }
        return endpoint;
    }

    /**
     * Updates the value of the applicant dealer checklist button
     */
    updateFieldValue(newValue) {
        this.applicantDealerChecklistBtn = newValue;
        return this;
    }

    /**
     * Sets up stores prior to rendering
     */
    preRenderSetup(config) {
        if (this.rideOctaneStore.isUXServer) {
            const applicationUuid = get(config, 'params.applicationUuid');
            const applicantUuid = get(config, 'params.applicantUuid');
            
            if (applicantUuid) {
                this.isApplicantCMPFlow = true;
            }
            if (applicationUuid) {
                // save applicationUuid to store (on server)
                this.applicationUuid = applicationUuid;
                this.applicationType = APPLICATION_TYPE.INDIVIDUAL;
                this.applicantUuid = applicantUuid;
                this.applicantType = APPLICANT_TYPE.PRIMARY;

                // set the current state of the application
                Promise.all([
                    this.refreshApplicationOffers()
                    // this method has to be synchronously called AFTER application's offers are fetched
                        .then(() => this.fetchVehicleConfiguration()),
                    this.refreshApplicationProgress(),
                    this.refreshApplicationSelectedOffer(),
                    this.refreshApplicationVerification(),
                    // preRenderSetup has to be called after everything is done
                ]).then(() => super.preRenderSetup());

                return this;
            }
        }

        return super.preRenderSetup();
    }

    /**
     * Reaches out to the api server to get the current set of available offers for the application
     */
    refreshApplicationOffers() {
        // partner endpoint if partner application
        let endpoint = `/applications/${this.applicationUuid}/status`;
        if (this.partnerStore.partnerIdentifier) {
            endpoint = `/partners/${this.partnerStore.partnerIdentifier}` + endpoint;
        }

        return this.apiStore.fetch(endpoint)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch status of app with uuid=${this.applicationUuid} (status=${status})`
                    );
                    return null;
                }
                return response;
            })
            .then(applicationOffers => this.updateApplicationOffers({applicationOffers}));
    }

    sendPostMessage = () => {
        const targetUrls = [];
        let decisionStatus = null;
        let paperworkUrl = null;
        let currentPage = 'additionalInfo';

        if (this.partnerStore.isEmbeddedPartner) {
            // get the target window where we want to send the postMessage to. 
            // On non-prod environments, this is set to emit to any listener.
            if (this.rideOctaneStore.settings.ENVIRONMENT_KEY !== 'production') {
                targetUrls.push('*');
            } else {
                targetUrls.push(this.partnerStore.partner.partner_url);
                if (this.partnerStore.partnerOriginUrl) {
                    targetUrls.push(new URL(this.partnerStore.partnerOriginUrl).origin);
                }
            }

            if (this.hasAvailableOffers && this.hasSubmittedAdditionalInfo) {
                decisionStatus = 'approved';
                currentPage = 'dealerChecklist';
                //construct the paperwork Url if a user has offers.
                paperworkUrl = this.rideOctaneStore.settings.BASE_UX_URL +
                    this.getPaperworkUrl('') +
                    '?' +
                    'partner=' + this.partnerStore.partnerIdentifier + '&' +
                    'dealership=' + this.dealershipStore.dealershipId + '&' +
                    'appTheme=' + this.historyStore.queryParams.appTheme
            } else if (!this.hasAvailableOffers && this.hasSubmittedAdditionalInfo) {
                decisionStatus = 'additional_review_required';
                currentPage = 'additionalInformationSubmittedRequiresReview';
            }
            const postMessageBody = {
                'decisionStatus': decisionStatus,
                'paperworkUrl': paperworkUrl,
                'currentPage': currentPage,
            };
            for (const targetUrl of targetUrls) {
                window.parent.postMessage(postMessageBody, targetUrl);
            }
        }
    };

    /**
     * Fetches the application's vehicle configuration from the api server
     */
    fetchVehicleConfiguration() {
        const options = {
            query: {
                fields: this.VEHICLE_CONFIGURATION_FIELDS,
            },
        };

        return this.apiStore.fetch(`configurations/${this.vehicleUuid}`, options)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch configuration with uuid=${this.vehicleUuid} (status=${status})`
                    );
                    return null;
                }

                this.vehicleConfiguration = response;
                return this;
            });
    }

    /**
     * Reaches out to the api server to get the current progress state for the application
     */
    refreshApplicationProgress() {
        if (this.isApplicantCMPFlow) {
            return this.refreshApplicantProgress();
        }
        return this.apiStore.fetch(`applications/${this.applicationUuid}/progress`)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch progress of app with uuid=${this.applicationUuid} (status=${status})`
                    );
                    return null;
                }
                this.readyToDecision = response.did_submit_additional_info;
                return response;
            })
            .then(applicationProgress => this.updateProgress({progress: applicationProgress}, 'application'));
    }

    /**
     * Reaches out to the api server to get the current progress state for the application with respect to this applicant
     *
     * - Re-updates application progress using the applicant-specific values, if available
     * - Sets type information for the application and applicant into the store
     *   @see APPLICATION_TYPE
     *   @see APPLICANT_TYPE
     */
    refreshApplicantProgress() {
        const progress = {
            application_type: APPLICATION_TYPE.INDIVIDUAL,
            applicant_type: APPLICANT_TYPE.PRIMARY,
            ready_to_decision: false,
            other_applicant_uuid: null,
            did_submit_social_security_number: false,
            did_submit_additional_info: false,
            did_submit_income_verification: false,
            did_submit_social_security_card: false,
            did_submit_proof_of_address: false,
            did_submit_drivers_license: false,
            did_submit_phone_bill: false,
            did_review_documents_to_bring: false,
        };
        const otherProgress = JSON.parse(JSON.stringify(progress));

        return this.apiStore.fetch(`applicant/${this.applicantUuid}/progress`)
            .then(({status, response}) => {

                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch applicant progress with uuid=${this.applicantUuid} (status=${status})`
                    );

                    response = {};
                }

                Object.keys(progress).forEach(key => progress[key] = response[key] || progress[key]);

                return progress;
            })
            .then(applicantProgress => {
                const {application_type, applicant_type, ready_to_decision, other_applicant_uuid} = applicantProgress;
                this.applicationType = application_type;
                this.applicantType = applicant_type;
                this.applicantDealerChecklistBtn = this.applicantType;
                this.readyToDecision = ready_to_decision;
                this.otherApplicantUuid = other_applicant_uuid;
                this.updateProgress({progress: applicantProgress}, 'applicant');
                this.updateProgress({progress: applicantProgress}, 'application');
                if (!this.otherApplicantUuid) {
                    return this;
                }
                return this.apiStore.fetch(`applicant/${this.otherApplicantUuid}/progress`)
                    .then(({status, response}) => {
                        if (status !== 200 || !response) {
                            console.warn(
                                `Failed to fetch applicant progress with uuid=${this.otherApplicantUuid} (status=${status})`
                            );       
                            response = {};
                        }
                        Object.keys(otherProgress).forEach(key => otherProgress[key] = response[key] || otherProgress[key]);
                        return otherProgress;
                    })
                    .then(otherApplicantProgress => {
                        this.updateProgress({progress: otherApplicantProgress}, 'otherApplicant');
                        return this;
                    })
            });
    }

    /**
     * Reaches out to api server to get the currently selected offer for the application
     */
    refreshApplicationSelectedOffer() {
        return this.apiStore.fetch(`applications/${this.applicationUuid}/selected_offer`)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch selected offer of app with uuid=${this.applicationUuid} (status=${status})`
                    );
                    return null;
                }
                return response.detail;
            })
            .then(applicationSelectedOffer => this.updateApplicationSelectedOffer({applicationSelectedOffer}));
    }

    /**
     * Reaches out to api server to get the verification state of the application
     */
    refreshApplicationVerification() {
        if (this.isApplicantCMPFlow) {
            return this.refreshApplicantVerification();
        }
        return this.apiStore.fetch(`applications/${this.applicationUuid}/verification`)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch verification of app with uuid=${this.applicationUuid} (status=${status})`
                    );
                    return [];
                }
                return response;
            })
            .then(CompleteMyPaperworkStore.parseVerificationResponse)
            .then(parsedResponse => this.updateVerification(parsedResponse, 'application'));
    }

    refreshApplicantVerification() {
        return this.apiStore.fetch(`applicant/${this.applicantUuid}/verification`)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(
                        `Failed to fetch verification of applicant with uuid=${this.applicantUuid} (status=${status})`
                    );
                    return [];
                }
                return response;
            })
            .then(CompleteMyPaperworkStore.parseVerificationResponse)
            .then(parsedResponse => {
                this.updateVerification(parsedResponse, 'applicant')
                if (!this.otherApplicantUuid) {
                    return this;
                }
                return this.apiStore.fetch(`applicant/${this.otherApplicantUuid}/verification`)
                  .then(({status, response}) => {
                    if (status !== 200 || !response) {
                        console.warn(
                            `Failed to fetch verification of applicant with uuid=${this.otherApplicantUuid} (status=${status})`
                        );
                        return [];
                    }
                    return response;
                  })
                  .then(CompleteMyPaperworkStore.parseVerificationResponse)
                  .then(parsedResponse => {
                    this.updateVerification(parsedResponse, 'otherApplicant')
                    return this;
                  })
            });
    }

    /**
     * Sends additional information to the api server
     */
    sendAdditionalInformation(additionalInfo) {
        const options = {
            method: 'PUT',
            data: this.constructor.cleanAdditionalInfoPayload(additionalInfo),
        };

        let statusEndpoint = `/applications/${this.applicationUuid}/status`;
        let additionalInfoEndpoint = `/applications/${this.applicationUuid}/additional_info`;
        if (this.isApplicantCMPFlow) {
            additionalInfoEndpoint = `/applicant/${this.applicantUuid}/additional_info`;
        }
        // use partner endpoints for partner applications
        if (this.partnerStore.partnerIdentifier) {
            statusEndpoint = `/partners/${this.partnerStore.partnerIdentifier}` + statusEndpoint;
            additionalInfoEndpoint = `/partners/${this.partnerStore.partnerIdentifier}` + additionalInfoEndpoint;
        }
        return this.apiStore.fetch(additionalInfoEndpoint, options)
            .then(({status, response}) => {
                if (status !== 200 || !response || !response.detail) {
                    console.error(`Received status code: ${status} (expected 200) after sending additional info.`);
                    return {error: true};
                }
                return pollOffers(statusEndpoint, this.apiStore).then(result => {
                    if (result.error) {
                        console.error('Error polling status endpoint after sending additional info.');
                        return {error: true};
                    }
                    return {error: false};
                });
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return Promise.all([
                    this.refreshApplicationOffers(),
                    this.refreshApplicationProgress(),
                    this.refreshApplicationVerification(),
                ]).then(() => result);
            });
    }

    /**
     * Sends selected offer to the api server
     */
    sendSelectedOffer(selectedOffer) {
        const options = {
            method: 'POST',
            data: this.constructor.cleanSelectedOfferPayload(selectedOffer),
        };

        return this.apiStore.fetch(`applications/${this.applicationUuid}/selected_offer`, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after selecting offer.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationSelectedOffer()
                    .then(() => result);
            });
    }

    /**
     * Sends request to set did_review_documents_to_bring to true
     */
    sendDidReviewDocumentsToBring = () => {
        const options = {
            method: 'POST',
            data: {did_review_documents_to_bring: true},
        };

        return this.apiStore.fetch(`applications/${this.applicationUuid}/progress`, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after reviewing docs to bring.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationProgress()
                    .then(() => result);
            });
    };

    /**
     * Uploads income verification documents
     */
    submitIncomeVerification(files) {
        const options = {
            method: 'POST',
            data: new FormData(),
            query: {
                type: DOCUMENT_TYPE.PROOF_OF_INCOME,
                category: DOCUMENT_CATEGORY.INCOME_VERIFICATION,
            },
        };

        // add each file to the body of the request
        files.forEach(file => options.data.append('files', file));

        return this.apiStore.fetch(this.submitVerificationEndpoint, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after submitting IV.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationProgress()
                    .then(() => result);
            });
    }

    /**
     * Uploads SSN verification documents
     */
    submitSSNVerification(files) {
        const options = {
            method: 'POST',
            data: new FormData(),
            query: {
                type: DOCUMENT_TYPE.SOCIAL_SECURITY_CARD,
                category: DOCUMENT_CATEGORY.CUSTOMER_VERIFICATION,
            },
        };

        // add each file to the body of the request
        files.forEach(file => options.data.append('files', file));

        return this.apiStore.fetch(this.submitVerificationEndpoint, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after submitting Social Security Card.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationProgress()
                    .then(() => result);
            });
    }

    /**
     * Uploads License verification documents
     */
    submitLicenseVerification(files) {
        const options = {
            method: 'POST',
            data: new FormData(),
            query: {
                type: DOCUMENT_TYPE.DRIVERS_LICENSE,
                category: DOCUMENT_CATEGORY.CUSTOMER_VERIFICATION,
            },
        };

        // add each file to the body of the request
        files.forEach(file => options.data.append('files', file));

        return this.apiStore.fetch(this.submitVerificationEndpoint, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after submitting Drivers License.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationProgress()
                    .then(() => result);
            });
    }

    /**
     * Uploads PhoneBill verification documents
     */
    submitPhoneBillVerification(files) {
        const options = {
            method: 'POST',
            data: new FormData(),
            query: {
                type: DOCUMENT_TYPE.PHONE_BILL,
                category: DOCUMENT_CATEGORY.CUSTOMER_VERIFICATION,
            },
        };

        // add each file to the body of the request
        files.forEach(file => options.data.append('files', file));

        return this.apiStore.fetch(this.submitVerificationEndpoint, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after submitting Phone Bill.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationProgress()
                    .then(() => result);
            });
    }

    /**
     * Uploads Proof Of Address verification documents
     */
    submitAddressVerification(files) {
        const options = {
            method: 'POST',
            data: new FormData(),
            query: {
                type: DOCUMENT_TYPE.PROOF_OF_ADDRESS,
                category: DOCUMENT_CATEGORY.CUSTOMER_VERIFICATION,
            },
        };

        // add each file to the body of the request
        files.forEach(file => options.data.append('files', file));

        return this.apiStore.fetch(this.submitVerificationEndpoint, options)
            .then(({status}) => {
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after submitting Proof Of Address.`);
                    return {error: true};
                }
                return {error: false};
            })
            .then(result => {
                if (result.error) {
                    return result;
                }
                return this.refreshApplicationProgress()
                    .then(() => result);
            });
    }

    /**
     * Updates applicationOffers observable
     */
    updateApplicationOffers({applicationOffers}) {
        this.applicationOffers = applicationOffers;
        return this;
    }

    /**
     * Updates progress observables for application, applicant or otherApplicant
     */
    updateProgress({progress}, prefix='application') {
        let fieldName = prefix + 'Progress';
        this[fieldName] = progress;
        return this;
    }

    /**
     * Updates applicationSelectedOffer observable
     */
    updateApplicationSelectedOffer({applicationSelectedOffer}) {
        this.applicationSelectedOffer = applicationSelectedOffer;
        return this;
    }

    /**
     * Updates all the verification observables for application, applicant or otherApplicant
     */
    updateVerification = (verifications, prefix) => {
        const {
            incomeVerification, 
            licenseVerification, 
            ssnVerification, 
            addressVerification,
            phoneBillVerification,
        } = verifications;
        return this
            .updateVerificationItem('IncomeVerification', {verification: incomeVerification}, prefix)
            .updateVerificationItem('SSNVerification', {verification: ssnVerification}, prefix)
            .updateVerificationItem('PhoneBillVerification', {verification: phoneBillVerification}, prefix)
            .updateVerificationItem('AddressVerification', {verification: addressVerification}, prefix)
            .updateVerificationItem('LicenseVerification', {verification: licenseVerification}, prefix);
    }

    /**
     * updates verfication observables
     *
     */
    updateVerificationItem(field, {verification}, prefix='application') {
        const fieldName = prefix + field;
        this[fieldName] = verification;
        return this;
    }

    /**
     * Helper - Returns url string for the specified subRoute
     *
     * @param {string} [subRoute]
     *  (e.g. additional-information, income-verification, income-verification/upload...)
     * @param {string} [uuid]
     *  (usually the otherApplicant's uuid, used in cmp applicant flow)
     */
    getPaperworkUrl = (subRoute = '', uuid = null) => {
        let paperworkUrl = `/paperwork/${this.applicationUuid}`;
        let applicantFlowUuid = this.applicantUuid;

        if (this.isApplicantCMPFlow) {
            if(uuid !== null){
                applicantFlowUuid = uuid;
            }
            paperworkUrl += `/${applicantFlowUuid}`;
        }
        return paperworkUrl + subRoute;
    }
}

decorate(CompleteMyPaperworkStore, {
    applicationOffers: observable,
    applicationProgress: observable,
    applicationSelectedOffer: observable,
    applicationIncomeVerification: observable,
    applicationSSNVerification: observable,
    applicationLicenseVerification: observable,
    applicationPhoneBillVerification: observable,
    applicationAddressVerification: observable,
    applicationUuid: observable,
    applicantUuid: observable,
    otherApplicantUuid: observable,
    isApplicantCMPFlow: observable,
    readyToDecision: observable,
    applicantDealerChecklistBtn: observable,
    applicantProgress: observable,
    applicantIncomeVerification: observable,
    applicantSSNVerification: observable,
    applicantLicenseVerification: observable,
    applicantPhoneBillVerification: observable,
    applicantAddressVerification: observable,
    otherApplicantProgress: observable,
    otherApplicantIncomeVerification: observable,
    otherApplicantSSNVerification: observable,
    otherApplicantLicenseVerification: observable,
    otherApplicantPhoneBillVerification: observable,
    otherApplicantAddressVerification: observable,

    prefix: computed,
    isInvalidApplication: computed,
    isExpiredApplication: computed,
    hasSubmittedAdditionalInfo: computed,
    hasSubmittedSocialSecurityNumber: computed,
    hasAvailableOffers: computed,
    hasSelectedOffer: computed,
    hasReviewedDocsToBring: computed,
    hasSubmittedIncomeVerification: computed,
    isIncomeVerificationRequired: computed,
    isIncomeVerificationAccepted: computed,
    isIncomeVerificationComplete: computed,
    hasSubmittedSSNVerification: computed,
    isSSNVerificationRequired: computed,
    isSSNVerificationAccepted: computed,
    isSSNVerificationComplete: computed,
    hasSubmittedLicenseVerification: computed,
    isLicenseVerificationRequired: computed,
    isLicenseVerificationAccepted: computed,
    isLicenseVerificationComplete: computed,
    hasSubmittedPhoneBillVerification: computed,
    isPhoneBillVerificationRequired: computed,
    isPhoneBillVerificationAccepted: computed,
    isPhoneBillVerificationComplete: computed,
    hasSubmittedAddressVerification: computed,
    isAddressVerificationRequired: computed,
    isAddressVerificationAccepted: computed,
    isAddressVerificationComplete: computed,

    vehicleUuid: computed,
    loanOffers: computed,
    selectedOffer: computed,
    incomeInstructionType: computed,
    incomeVerificationStatus: computed,
    ssnVerificationStatus: computed,
    licenseVerificationStatus: computed,
    addressVerificationStatus:computed,
    phoneBillVerificationStatus: computed,

    showTransparentOffers: computed,
    showApplicationDealerApprovedView: computed,
    showApplicationDealerDeclinedView: computed,
    showDriversLicense: computed,

    tradeInUrl: computed,
    financeUrl: computed,
    submitVerificationEndpoint: computed,

    updateApplicationOffers: action,
    updateProgress: action,
    updateApplicationSelectedOffer: action,
    updateFieldValue: action,
    updateVerification: action,
    updateVerificationItem: action,
});


/**
 * Cleans additional info and returns a payload.
 * @param rawAdditionalInfo
 */
CompleteMyPaperworkStore.cleanAdditionalInfoPayload = rawAdditionalInfo => {
    let cleanPayload = {};

    // get employed time (months & years)
    const employedSince = new Date(
        parseInt(rawAdditionalInfo.employer_date.year),
        parseInt(rawAdditionalInfo.employer_date.month) - 1, // subtract 1 because dates are zero indexed
    );
    const employedTime = getTimeSinceDate(employedSince);

    cleanPayload.citizenship_status = rawAdditionalInfo.citizenship_status.trim();
    cleanPayload.employer_months = `${employedTime.months}`.trim();
    cleanPayload.employer_years = `${employedTime.years}`.trim();
    cleanPayload.employer_name = rawAdditionalInfo.employer_name.trim();
    cleanPayload.employer_phone = CompleteMyPaperworkStore.formatPhoneForPayload(rawAdditionalInfo.employer_phone.trim());
    cleanPayload.job_title = rawAdditionalInfo.job_title.trim();

    cleanPayload.monthly_housing_payment = cleanNumberString(rawAdditionalInfo.monthly_housing_payment.trim());

    if(rawAdditionalInfo.ssn) {
        cleanPayload.social_security_number = formatSSN(rawAdditionalInfo.ssn.trim());
    }

    return cleanPayload;
};

/**
 * Returns phoneNumberString in the following format: ###-###-####
 * Returns empty string if phoneNumberString is invalid.
 * @param phoneNumberString {string} - needs to be 10 numeric characters, can include other non numeric characters.
 * @returns {string}
 */
CompleteMyPaperworkStore.formatPhoneForPayload = phoneNumberString => {
    phoneNumberString = phoneNumberString.replace(/\D/g, '');
    if (phoneNumberString.length !== 10) {
        return '';
    }

    return `${phoneNumberString.substr(0,3)}-` +
        `${phoneNumberString.substr(3,3)}-` +
        `${phoneNumberString.substr(6,4)}`;
};

/**
 * Cleans selected offer and returns a payload.
 * @param rawSelectedOffer
 */
CompleteMyPaperworkStore.cleanSelectedOfferPayload = rawSelectedOffer => {
    const cleanPayload = {};

    cleanPayload.apr = rawSelectedOffer.apr;
    cleanPayload.term = rawSelectedOffer.term;
    cleanPayload.offer_type = rawSelectedOffer.offer_type;

    return cleanPayload;
};

/**
 * Parses the response from the /verification endpoint to get the status for each type of verification
 *
 * @param {array} verifications
 *  e.g.
 *      [
 *          {type, category, status, instruction_type},
 *          {type, category, status, instruction_type},
 *          {type, category, status, instruction_type},
 *          {type, category, status, instruction_type},
 *          {type, category, status, instruction_type},
 *          ...
 *      ]
 *
 * @returns {object}
 *  e.g.
 *      {
 *          incomeVerification: {type, category, status, instruction_type}
 *          licenseVerification: {type, category, status, instruction_type}
 *          ssnVerification: {type, category, status, instruction_type}
 *          addressVerification: {type, category, status, instruction_type}
 *          phoneBillVerification: {type, category, status, instruction_type}
 *      }
 */
CompleteMyPaperworkStore.parseVerificationResponse = verifications => {
    let incomeVerification = null;
    let licenseVerification = null;
    let ssnVerification = null;
    let addressVerification = null;
    let phoneBillVerification  = null;

    verifications.forEach(verification => {
        const verificationType = verification.type.toLowerCase();
        const verificationCategory = verification.category.toLowerCase();

        if (verificationCategory === DOCUMENT_CATEGORY.INCOME_VERIFICATION) {
            if (verificationType === DOCUMENT_TYPE.PROOF_OF_INCOME) {
                // Income Verification
                incomeVerification = verification;

            }
        } else if (verificationCategory === DOCUMENT_CATEGORY.CUSTOMER_VERIFICATION) {
            if (verificationType === DOCUMENT_TYPE.DRIVERS_LICENSE) {
                // License Verification
                licenseVerification = verification;

            } else if (verificationType === DOCUMENT_TYPE.SOCIAL_SECURITY_CARD) {
                // Social Security Number Verification
                ssnVerification = verification;

            } else if (verificationType === DOCUMENT_TYPE.PROOF_OF_ADDRESS) {
                // Address Verification
                addressVerification = verification;

            } else if (verificationType === DOCUMENT_TYPE.PHONE_BILL) {
                // Phone Bill Verification
                phoneBillVerification = verification;

            }
        }
    });

    return {
        incomeVerification,
        licenseVerification,
        ssnVerification,
        addressVerification,
        phoneBillVerification,
    };
};
