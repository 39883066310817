import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import {inject, observer} from 'mobx-react';
import {observable, reaction, runInAction} from 'mobx';

import {Container} from 'react-bootstrap';

import {Input} from "../../components/input";
import copy from "./copy";
import {ListDropdown} from "../../components/dropdown";
import {AdditionalInfoStore} from "./additional-info-store";
import {CTAFooter} from "../../components/cta-footer";
import {SSNInput} from "../../components/ssn-input";
import {
    SubmittingAdditionalInformation,
    AdditionalInformationSubmittedSuccess,
    AdditionalInformationSubmittedRequiresReview,
} from "./interstitials";
import {DisclosureBlock} from "../../components/disclosure-block";
import {DateInput} from "../../components/date-input";
import {DateInputType} from "../../utils/date-utils";
import classNames from "classnames";
import styles from "./additional-info.module.scss";
import {isInIFrame} from "../../utils/iframe-utils";
import {ReveoCTAFooter} from "../../components/reveo-cta-footer";
import {BrandingValueProp} from "../../components/branding";
import {APPLICATION_TYPE} from "../../enums/applications";
import {APPLICANT_TYPE} from "../../enums/applicants";
import {pushApplicationFlow, pushOfferSubmit} from "../../utils/gtm-utils";

export const AdditionalInfo = inject('completeMyPaperworkStore', 'uxCopyStore', 'partnerStore')(
    observer(
        class _AdditionalInfo extends React.Component {
            static propTypes = {
                completeMyPaperworkStore: PropTypes.shape({
                    isApplicantCMPFlow: PropTypes.bool.isRequired,
                    applicationType: PropTypes.string.isRequired,
                    applicantType: PropTypes.string.isRequired,
                    isInvalidApplication: PropTypes.bool.isRequired,
                    hasSubmittedAdditionalInfo: PropTypes.bool.isRequired,
                    getPaperworkUrl: PropTypes.func.isRequired,
                    sendAdditionalInformation: PropTypes.func.isRequired,
                }).isRequired,
            };

            // keep track of ui state
            uiState = observable({
                isSubmitting: false,
                didSubmitForm: false,
                isInIframe: false,
                isLoaded: false,
            });

            //scroll to top at load
            componentDidMount() {
                window.scrollTo(0,0);
                this.uiState.isLoaded = true;
                this.uiState.isInIframe = isInIFrame();
                const applicationUuid = this.props.completeMyPaperworkStore.applicationUuid;
                pushApplicationFlow(applicationUuid, 'additional_info');

                this.props.completeMyPaperworkStore.sendPostMessage();
            }

            //scrolls to top when this.uiState.isSubmitting changes values
            scrollForSubmittingChange = reaction(
                () => this.uiState.isSubmitting,
                (isSubmitting) => window.scrollTo(0,0)
            );

            store = new AdditionalInfoStore({
                hasSubmittedSocialSecurityNumber: this.props.completeMyPaperworkStore.hasSubmittedSocialSecurityNumber
            });

            /**
             * False if the application is invalid or the customer
             * submitted their additional info prior to this session
             */
            get canViewPage() {
                const {completeMyPaperworkStore} = this.props;

                // check if this is a valid application
                if (completeMyPaperworkStore.isInvalidApplication) {
                    return false; // app was not found, has expired or there was an error
                }

                // check if additional info was submitted before the form was submitted
                if (completeMyPaperworkStore.hasSubmittedAdditionalInfo) {
                    if (!this.uiState.didSubmitForm) {
                        return false;
                    }
                }
                return true;
            }

            /**
             * Invoked when the user submits their additional information
             */
            onSubmit = e => {
                e.preventDefault();
                const {completeMyPaperworkStore} = this.props;

                // update didSubmitForm / isSubmitting -> true
                runInAction(() => {
                    this.uiState.isSubmitting = true;
                    this.uiState.didSubmitForm = true;
                });

                // send the additional information
                completeMyPaperworkStore.sendAdditionalInformation(this.store.additionalInfo)
                    .then(() =>
                        // update isSubmitting -> false
                        runInAction(() => this.uiState.isSubmitting = false)
                    );
            };

            InputField = observer(({field, onChange}) => (
                <Input
                    {...copy[field]}
                    controlId={field}
                    onBlur={onChange}
                    onChange={onChange}
                    value={this.store.additionalInfo[field]}
                    error={this.store.isFieldInvalid(field)}
                />
            ));

            NumericInputField = observer(({field, onChange, errorMessage}) => (
                <Input
                    {...copy[field]}
                    controlId={field}
                    type="tel"
                    onBlur={onChange}
                    onChange={onChange}
                    value={this.store.additionalInfo[field]}
                    error={this.store.isFieldInvalid(field) && errorMessage}
                />
            ));

            DropdownField = observer(({field, onChange}) => (
                <ListDropdown
                    {...copy[field]}
                    controlId={field}
                    onChange={onChange}
                    value={this.store.additionalInfo[field]}
                    items={this.store.getFieldItems(field)}
                    error={this.store.isFieldInvalid(field)}
                />
            ));

            onChangeCitizenshipStatus = e => this.store.updateAdditionalInfoField('citizenship_status', e.key);
            onChangeSSN = e => (
                this.store.updateAdditionalInfoField('ssn', e.target.value, e.type === 'blur')
            );
            onChangeMonthlyHousingPayment = e => (
                this.store.updateAdditionalInfoField('monthly_housing_payment', e.target.value, e.type === 'blur')
            );
            onChangeJobTitle = e =>(
                this.store.updateAdditionalInfoField('job_title', e.target.value, e.type === 'blur')
            );
            onChangeEmployerName = e => (
                this.store.updateAdditionalInfoField('employer_name', e.target.value, e.type === 'blur')
            );
            onChangeEmployerPhone = e => (
                this.store.updateAdditionalInfoField('employer_phone', e.target.value, e.type === 'blur')
            );
            onChangeEmployerDate = (e, fullDate) => (
                this.store.updateAdditionalInfoField('employer_date', fullDate, e.type === 'blur')
            );

            AdditionalInfoTitle = () => {
                const cmpStore = this.props.completeMyPaperworkStore;
                let bottomMargin = 4;
                let key = 'title';
                let defaultTitle = copy.title;
                if (cmpStore.isApplicantCMPFlow) {
                    let type = 'individual';
                    if (cmpStore.applicationType === APPLICATION_TYPE.JOINT) {
                        type = 'joint';
                        bottomMargin = 2;
                    }
                    key = `titles.${type}`;
                    defaultTitle = copy.titles[type];
                }
                let title = this.props.uxCopyStore.getAdditionalInfoCopy(key) || defaultTitle;
                if (Array.isArray(title)) {
                    title = title.map((line, i) => <div key={i}>{line}</div>);
                }
                return (
                    <div className={`w-450px mw-100 mx-auto mt-4 mb-${bottomMargin} pb-1`}>
                        <h5 className="text-center font-weight-bold">{title}</h5>
                    </div>
                );
            }
            AdditionalInfoSubtitle = () => {
                const cmpStore = this.props.completeMyPaperworkStore;
                if (cmpStore.applicationType === APPLICATION_TYPE.JOINT) {
                    const type = (cmpStore.applicantType === APPLICANT_TYPE.COAPPLICANT) ? 'coapplicant' : 'primary';
                    const subtitle = this.props.uxCopyStore.getAdditionalInfoCopy(`subtitles.${type}`);
                    return (
                        <h6 className="text-center spark-text-primary spark-text-karla spark-text-20px font-weight-bold border-top pt-3 mb-4">
                            {subtitle ? subtitle : copy.subtitles[type]}
                        </h6>
                    )
                }
                return '';
            }
            SubmitButton = observer(() => {
                const oid = "additional-info-submit";
                const submitButtonLabel = this.props.uxCopyStore.getAdditionalInfoCopy('submit_button.label', copy.submit_button.label);
                // ensures that the original CTA isn't rendered when waiting for browser to render
                if (!this.uiState.isLoaded) {
                    return null;
                }

                // if is Reveo and in an Iframe return ReveoFooter
                if (this.props.partnerStore.isReveo && this.uiState.isInIframe) {
                    return (
                        <ReveoCTAFooter
                            data-oid={oid}
                            disabled={!this.store.canContinue}
                            onClick={this.onSubmit}
                        />
                    );
                }
                return (
                    <CTAFooter
                        primary={{
                            oid: oid,
                            label: submitButtonLabel,
                            isDisabled: !this.store.canContinue,
                            onClick: this.onSubmit,
                        }}
                        isSticky={!(this.props.partnerStore.isRV && this.uiState.isInIframe)}
                    />
                );
            });

            CitizenshipStatusDropdown = () => (
                <div>
                    <this.DropdownField field="citizenship_status" onChange={this.onChangeCitizenshipStatus}/>
                </div>
            );
            SSNInputAdditonalInfo = observer(() => (
                <div>
                    <SSNInput
                        {...copy["ssn"]}
                        onChange={this.onChangeSSN}
                        value={this.store.additionalInfo["ssn"]}
                        error={this.store.isFieldInvalid("ssn")}
                    />
                </div>
            ));
            MonthlyHousingPaymentInput = () => (
                <div>
                    <this.NumericInputField field="monthly_housing_payment" onChange={this.onChangeMonthlyHousingPayment}/>
                </div>
            );
            JobTitleInput = () => (
                <div>
                    <this.InputField field="job_title" onChange={this.onChangeJobTitle}/>
                </div>
            );
            EmployerNameInput = () => (
                <div>
                    <this.InputField field="employer_name" onChange={this.onChangeEmployerName}/>
                </div>
            );
            EmployerPhoneInput = () => (
                <div>
                    <this.NumericInputField 
                        field="employer_phone" 
                        onChange={this.onChangeEmployerPhone} 
                        errorMessage={copy.employer_phone.error_message}
                    />
                </div>
            );

            EmployerDateInput = observer(() => (
                <div>
                    <DateInput
                        {...copy.employer_date}
                        controlId="employer_date"
                        field="employer_date"
                        value={this.store.additionalInfo["employer_date"]}
                        error={this.store.isFieldInvalid("employer_date")}
                        onChange={this.onChangeEmployerDate}
                        onBlur={this.onChangeEmployerDate}
                        inputType={DateInputType.MONTH_AND_YEAR}
                    />
                </div>
            ));

            Disclosure = () => (
                <div>
                    <DisclosureBlock
                        showRuleLine
                        disclosureText={copy.disclosure}
                    />
                </div>
            );

            render() {
                const {completeMyPaperworkStore, partnerStore} = this.props;
                const paperworkUrl = completeMyPaperworkStore.getPaperworkUrl();

                // fallback to dealer checklist
                if (!this.canViewPage) {
                    return <Redirect to={paperworkUrl}/>;
                }

                // is submitting...
                if (this.uiState.isSubmitting) {
                    return <SubmittingAdditionalInformation/>;
                }

                // finished submitting!
                if (this.uiState.didSubmitForm) {
                    pushOfferSubmit('offer_submitted', completeMyPaperworkStore.applicationUuid);
                    if (!completeMyPaperworkStore.readyToDecision) {
                        return <Redirect to={completeMyPaperworkStore.getPaperworkUrl('/additional-info/pending')}/>
                    }
                    // Low transparency or showApplicationDealer(Approved/Declined)View goes directly to Dealer Checklist
                    if (
                        !completeMyPaperworkStore.showTransparentOffers || 
                        completeMyPaperworkStore.showApplicationDealerDeclinedView ||
                        completeMyPaperworkStore.showApplicationDealerApprovedView
                    ) {
                        return <Redirect to={paperworkUrl}/>;
                    }

                    // Approved w/ Offers
                    if (completeMyPaperworkStore.hasAvailableOffers) {
                        return (
                            <AdditionalInformationSubmittedSuccess paperworkUrl={paperworkUrl} isSticky={!(partnerStore.isRV && isInIFrame())}/>
                        );
                    }

                    // Manual Review
                    return <AdditionalInformationSubmittedRequiresReview/>;
                }

                // Additional Information Form
                return (
                    <Container className={classNames({[styles.container]: this.uiState.isLoaded && partnerStore.hasMultiColumnDisplay})}>
                        <this.AdditionalInfoTitle/>
                        <this.AdditionalInfoSubtitle/>
                        <div className={classNames("w-450px mw-100 mb-5 mx-auto", {
                            [`w-md-800px w-sm-600px ${styles.form}`]: this.uiState.isLoaded && partnerStore.hasMultiColumnDisplay,
                        })}>
                            <this.CitizenshipStatusDropdown/>
                            <this.MonthlyHousingPaymentInput/>
                            <this.EmployerNameInput/>
                            <this.EmployerPhoneInput/>
                            <this.JobTitleInput/>
                            <this.EmployerDateInput/>
                            { !completeMyPaperworkStore.hasSubmittedSocialSecurityNumber && <this.SSNInputAdditonalInfo/> }
                            <this.Disclosure/>
                            {!!this.props.partnerStore.isRV &&
                            <BrandingValueProp
                                showPoweredByOctane
                                showDesktopLayout
                                showBrandingForCampingWorld
                            />}
                            <this.SubmitButton/>
                        </div>
                    </Container>
                );
            }
        }
    )
);
