import React from 'react';
import PropTypes from 'prop-types';
import template from 'lodash.template';
import {observer, inject} from 'mobx-react';
import {observable, runInAction, reaction} from 'mobx';
import {Redirect} from "react-router-dom";
import {Container} from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html";
import {isInIFrame} from "../../utils/iframe-utils";

import {LayoutComponent} from "../../components/layout-component";
import {income, incomeUploaded} from '../../components/svg/graphics';

import {
    SubmittingVerification,
    VerificationSubmittedSuccess,
    VerificationSubmittedError} from "./verification-interstitial";
import {UploadCTAFooter} from "./upload-cta-footer";

import copy from './copy';
import incomeInstructions from './income-instructions';


export const IncomeVerification = inject('completeMyPaperworkStore', 'partnerStore')(
    observer(
        class _IncomeVerification extends React.Component {
            static propTypes = {
                completeMyPaperworkStore: PropTypes.shape({
                    isInvalidApplication: PropTypes.bool.isRequired,
                    hasSubmittedAdditionalInfo: PropTypes.bool.isRequired,
                    incomeInstructionType: PropTypes.string.isRequired,
                    isIncomeVerificationComplete: PropTypes.bool.isRequired,
                    isIncomeVerificationAccepted: PropTypes.bool.isRequired,

                    getPaperworkUrl: PropTypes.func.isRequired,
                    submitIncomeVerification: PropTypes.func.isRequired,
                }).isRequired,
            };

            // keep track of ui state
            uiState = observable({
                isSubmitting: false,
                filesSelected: false,
                uploadError: false,
            });

            //scroll to top when mount, might need to update in between tho
            componentDidMount() {
                window.scrollTo(0,0);
            }

            componentWillUnmount() {
                // dispose reaction
                this.disposeScrollForSubmittingChange();
            }

            //scrolls to top when the view changes
            disposeScrollForSubmittingChange = reaction(
                () => {
                    if (this.uiState.isSubmitting) {
                        return 'is-submitting';
                    }
                    if (this.uiState.filesSelected) {
                        return 'has-submitted';
                    }
                    return 'not-submitted';
                },
                (isSubmitting) => window.scroll(0,0)
            );

            /**
             * False if the application is invalid, the customer has not submitted additional info
             */
            get canViewPage() {
                const {completeMyPaperworkStore} = this.props;
                // check if this is a valid application
                if (completeMyPaperworkStore.isInvalidApplication) {
                    return false; // app was not found, has expired or there was an error
                }

                if (!completeMyPaperworkStore.hasSubmittedAdditionalInfo) {
                    return false; // app was found, but the customer has not filled in their additional info
                }

                if (completeMyPaperworkStore.isIncomeVerificationAccepted) {
                    return false; //if income verification has been accepted or was not required
                }

                return true;
            }

            submitIncomeVerification = ({files}) => {
                const {completeMyPaperworkStore} = this.props;

                // update filesSelected / isSubmitting -> true
                runInAction(() => {
                    this.uiState.isSubmitting = true;
                    this.uiState.filesSelected = files;
                });

                return completeMyPaperworkStore.submitIncomeVerification(files)
                    .then((result) => {
                        // update isSubmitting -> false
                        runInAction(() => {
                            this.uiState.isSubmitting = false;
                            this.uiState.uploadError = result?.error;
                        });
                    });
            };

            IncomeDocumentInstructions = observer(() => {
                const prevYear = new Date().getFullYear() - 1;
                const incomeInstruction = incomeInstructions[this.props.completeMyPaperworkStore.incomeInstructionType];

                return (
                    <div className="w-350px mx-auto">
                        <div className="mb-3 text-center spark-text-secondary">
                            <ReactMarkdown
                                source={template(incomeInstruction.header)({prevYear})}
                                renderers={{
                                    strong: props => <strong className="spark-text-dark">{props.children}</strong>
                                }}
                            />
                        </div>
                        <div className="w-350px mx-auto mb-4 p-4 border border-secondary">
                            <ReactMarkdown source={template(incomeInstruction.document1)({prevYear})}/>
                            <p className="small spark-text-primary my-2">
                                <strong>{incomeInstruction.join}</strong>
                            </p>
                            <ReactMarkdown source={template(incomeInstruction.document2)({prevYear})}/>
                        </div>
                    </div>
                );

            });

            uploadMoreDocuments = () => {
                 runInAction(() => {
                    this.uiState.isSubmitting = false;
                    this.uiState.filesSelected = false;
                    this.uiState.uploadError = false;
                });
            };

            render() {
                const {completeMyPaperworkStore, partnerStore} = this.props;
                const numFilesSelected = this.uiState.filesSelected.length;

                // fallback to dealer checklist
                if (!this.canViewPage) {
                    return <Redirect to={completeMyPaperworkStore.getPaperworkUrl()}/>;
                }

                //renders if isSubmitting
                if (this.uiState.isSubmitting) {
                    return (
                        <SubmittingVerification
                            numDocs={numFilesSelected}
                        />
                    );
                }

                //renders when upload complete
                if (this.uiState.filesSelected) {
                    if (this.uiState.uploadError) {
                        return (
                            <VerificationSubmittedError
                                numDocs={numFilesSelected}
                                paperworkUrl={completeMyPaperworkStore.getPaperworkUrl()}
                                uploadOtherDocuments={this.uploadMoreDocuments}
                                isReveo={partnerStore.isReveo}
                            />
                        );
                    }
                    // if uiState finishing submitting (without errors)
                    return (
                        <VerificationSubmittedSuccess
                            icon={incomeUploaded}
                            numDocs={numFilesSelected}
                            paperworkUrl={completeMyPaperworkStore.getPaperworkUrl()}
                            uploadMoreDocuments={this.uploadMoreDocuments}
                            isEmbedded={partnerStore.isEmbeddedPartner}
                        />
                    );
                }

                const incomeVerificationCopy = (
                    !completeMyPaperworkStore.isIncomeVerificationComplete ?
                        copy.income_verification : copy.income_verification_resubmit
                );

                return (
                    <Container className="mt-5">
                        {/* Layout with svg icon, headline */}
                        <LayoutComponent
                            icon={income}
                            headline={incomeVerificationCopy.title}
                        />

                        {/* Verification Text */}
                        {incomeVerificationCopy.text ? (
                            <div
                                className="w-350px mw-100 mx-auto mb-3 text-center spark-text-secondary">
                                <ReactMarkdown
                                    source={incomeVerificationCopy.text}
                                    renderers={{
                                        paragraph: props => <p className="mb-4">{props.children}</p>,
                                    }}
                                />
                            </div>
                        ) : (
                            <this.IncomeDocumentInstructions/>
                        )}

                        {/* Upload Income Verification Sticky CTA Footer */}
                        <UploadCTAFooter
                            onFilesSelected={this.submitIncomeVerification}
                            secondaryHref={completeMyPaperworkStore.getPaperworkUrl()}
                            isSticky={!(partnerStore.isEmbeddedPartner && isInIFrame())}
                        />
                    </Container>
                );
            }
        }
    )
);
