export const VERIFICATION_STATUSES = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    ERROR: 'error',
    PROCESSING: 'processing',
    NOT_REQUIRED: 'not-required',
};

export const INCOME_INSTRUCTION_TYPES = {
    DEFAULT: 'DEFAULT',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    NEW_EMPLOYEE: 'NEW_EMPLOYEE',
    EMPLOYED_LESS_THAN_YEAR: 'EMPLOYED_LESS_THAN_YEAR',
    FIRST_TIME_BUYER_SELF_EMPLOYED: 'FIRST_TIME_BUYER_SELF_EMPLOYED',
    FIRST_TIME_BUYER_EMPLOYED: 'FIRST_TIME_BUYER_EMPLOYED',
    NO_CREDIT_SCORE_SELF_EMPLOYED: 'NO_CREDIT_SCORE_SELF_EMPLOYED',
    NO_CREDIT_SCORE_EMPLOYED: 'NO_CREDIT_SCORE_EMPLOYED',
    MILITARY: 'MILITARY',
    RETIRED: 'RETIRED',
};
