import React from 'react';
import PropTypes from 'prop-types';
import {Route} from "react-router-dom";
import {observable} from 'mobx';
import {observer, inject} from 'mobx-react';
import {Container} from 'react-bootstrap';
import classNames from "classnames";

import {ReturnToServer} from "../../components/return-to-server";
import {ReviewOffersModalHeader, ReviewOffersModalContent} from "./review-offers-modal";
import {AdditionalInformationSubmittedRequiresReview} from "../additional-info/interstitials";
import {YourFinancingChecklistItem} from "./checklist-items/your-financing-checklist-item";
import {InfoToReviewChecklistItem} from "./checklist-items/info-to-review-checklist-item";
import {VehicleChecklistItem} from "./checklist-items/vehicle-checklist-item";
import {IncomeVerificationChecklistItem} from "./checklist-items/income-verification-checklist-item";
import {SSNVerificationChecklistItem} from "./checklist-items/ssn-verification-checklist-item";
import {PhoneBillVerificationChecklistItem} from "./checklist-items/phone-bill-verification-checklist-item";
import {LicenseVerificationChecklistItem} from "./checklist-items/license-verification-checklist-item";
import {AddressVerificationChecklistItem} from "./checklist-items/address-verification-checklist-item";
import {OffersLayout} from "../../components/offers/offers-layout";
import {OffersDeclined} from "../../components/offers/offers-declined";
import {LayoutComponent} from '../../components/layout-component';
import {ReturnHomeCta, CTAFooter} from "../../components/cta-footer";
import {completeMyPaperwork, offerExpired} from '../../components/svg/graphics';
import {DisclosureBlock} from "../../components/disclosure-block";
import {ButtonSelectField} from "../../components/button-select-field";
import {isInIFrame} from "../../utils/iframe-utils";
import {APPLICATION_TYPE} from "../../enums/applications";

import copy from './copy.json';
import {ReveoCTAFooter} from "../../components/reveo-cta-footer";
import {BrandingValueProp} from "../../components/branding";

export const DealerChecklist = inject('completeMyPaperworkStore', 'rideOctaneModalStore', 'partnerStore', 'dealershipStore', 'uxCopyStore')(
    observer(
        class _DealerChecklist extends React.Component {
            static propTypes = {
                completeMyPaperworkStore: PropTypes.shape({
                    isInvalidApplication: PropTypes.bool.isRequired,
                    isExpiredApplication: PropTypes.bool.isRequired,
                    showTransparentOffers: PropTypes.bool.isRequired,
                    showDriversLicense: PropTypes.bool.isRequired,
                    hasSubmittedAdditionalInfo: PropTypes.bool.isRequired,
                    hasAvailableOffers: PropTypes.bool.isRequired,
                    hasSelectedOffer: PropTypes.bool.isRequired,
                    hasReviewedDocsToBring: PropTypes.bool.isRequired,
                    getPaperworkUrl: PropTypes.func.isRequired,
                    applicationUuid: PropTypes.string.isRequired,

                    vehicleConfiguration: PropTypes.object,
                    sendDidReviewDocumentsToBring: PropTypes.func.isRequired,
                }).isRequired,
                rideOctaneModalStore: PropTypes.shape({
                    openModal: PropTypes.func.isRequired,
                }).isRequired,
            };

            uiState = observable({
                isInIframe: false,
                isLoaded: false,
                didRenderOnce: false,
            });

            componentDidMount() {
                //scroll to top of page
                window.scrollTo(0, 0);

                this.uiState.isInIframe = isInIFrame();
                this.uiState.isLoaded = true;

                const {
                    isInvalidApplication,
                    isExpiredApplication,
                    readyToDecision,
                    hasAvailableOffers,
                    hasSelectedOffer,
                    showTransparentOffers,
                    sendPostMessage,
                } = this.props.completeMyPaperworkStore;

                // open the modal if the user has not selected an offer and needs to
                if (showTransparentOffers &&
                    !isInvalidApplication && !isExpiredApplication &&
                    readyToDecision && hasAvailableOffers && !hasSelectedOffer) {
                    this.openReviewOffersModal();
                }
                sendPostMessage();
            }

            openReviewOffersModal = () => {
                const {rideOctaneModalStore, completeMyPaperworkStore} = this.props;

                rideOctaneModalStore.openModal({
                    ModalHeader: ReviewOffersModalHeader,
                    ModalContent: ReviewOffersModalContent,
                    props: {
                        copy: copy.review_offers_modal,
                    },
                    preventClose: !completeMyPaperworkStore.hasSelectedOffer,
                });
            };

            // helper used in contactInfo to split multi line addresses for formatting
            _formatContactAddress = address => address ? address.split('\n') : void 0;

            getDealershipOrPartnerContactInfo() {
                const partner = this.props.partnerStore.partner;
                const dealership = this.props.dealershipStore.dealership;

                if (dealership && dealership.contact_name) {
                    return {
                        contact_name: dealership.contact_name,
                        contact_address: this._formatContactAddress(
                            dealership.contact_address
                        ),
                        contact_phone_number: dealership.contact_phone_number,
                        contact_email_address: dealership.contact_email_address,
                    };
                } else if (partner && partner.contact_name) {
                    return {
                        contact_name: partner.contact_name,
                        contact_address: this._formatContactAddress(
                            partner.contact_address
                        ),
                        contact_phone_number: partner.contact_phone_number,
                        contact_email_address: partner.contact_email_address,
                    };
                }
                return null;
            }

            LandingPageSubmitButton = observer(() => {
                const {completeMyPaperworkStore, partnerStore} = this.props;

                // if UI state isn't loading, return no CTA
                if (!this.uiState.isLoaded) {
                    return null;
                }

                const cmpUrl = completeMyPaperworkStore.hasSubmittedAdditionalInfo ? completeMyPaperworkStore.getPaperworkUrl('/additional-info/pending') : completeMyPaperworkStore.getPaperworkUrl('/additional-info');

                // is isReveo and inside an iframe, render ReveoCTAFooter
                if (partnerStore.isReveo && this.uiState.isInIframe) {
                    return (
                        <ReveoCTAFooter href={cmpUrl}/>
                    );
                }

                return (
                    <CTAFooter
                        isSticky={!(partnerStore.isRV && this.uiState.isInIframe)}
                        primary={{
                            label: copy.complete_your_paperwork.button,
                            href: cmpUrl,
                        }}
                    />
                );
            });

            DealerChecklistTitle = () => {
                const title = this.props.uxCopyStore.getDealerChecklistCopy('title', copy.dealer_checklist.title);
                const text = this.props.uxCopyStore.getDealerChecklistCopy('text', copy.dealer_checklist.text);
                return (
                    <div>
                        <p className="spark-text-24px-bold">
                            {title}
                        </p>
                        <p className="spark-text-12px spark-text-16px-desk">
                            {text}
                        </p>
                    </div>
                );
            }

            ApplicantSelectionBtns = () => {
                const {completeMyPaperworkStore} = this.props;
                return (
                    <ButtonSelectField
                        {...copy.applicant_dealer_checklist}
                        controlId="applicant_dealer_checklist"
                        selected={completeMyPaperworkStore.applicantDealerChecklistBtn}
                        onChange={(event) => {
                            completeMyPaperworkStore.updateFieldValue(event.key);
                        }}
                    />
                );
            }

            render() {
                const {completeMyPaperworkStore, partnerStore} = this.props;

                if (!this.uiState.didRenderOnce) {
                    completeMyPaperworkStore.updateFieldValue(completeMyPaperworkStore.applicantType);
                    this.uiState.didRenderOnce = true;
                }

                const isReveoInIframe = partnerStore.isReveo && this.uiState.isInIframe;

                // fallback to server to render 404 or 500
                if (completeMyPaperworkStore.isInvalidApplication) {
                    return <Route component={ReturnToServer}/>;
                }

                // Expired Application
                if (completeMyPaperworkStore.isExpiredApplication) {
                    return (
                        <Container className="mt-5" data-oid="cmp-offer-expired-page">
                            {/* Layout with svg icon, headline and bodytext */}
                            <LayoutComponent
                                icon={offerExpired}
                                headline={copy.offer_expired.title}
                                bodyText={copy.offer_expired.text}
                            />
                            <ReturnHomeCta/>
                        </Container>
                    );
                }

                // Additional Information Flow
                if (!completeMyPaperworkStore.readyToDecision) {
                    return (
                        <Container>
                            <div className="py-5 w-500px mw-100 mx-auto text-center">
                                {/* Layout with svg icon, headline and body text */}
                                <LayoutComponent
                                    icon={completeMyPaperwork}
                                    headline={copy.complete_your_paperwork.title}
                                    bodyText={copy.complete_your_paperwork.text}
                                />
                                <this.LandingPageSubmitButton/>
                            </div>
                        </Container>
                    );
                }

                // Dealer Approved
                if (completeMyPaperworkStore.showApplicationDealerApprovedView) {
                    return (
                        <Container>
                            <OffersLayout
                                showDecisionBlock
                                decisionBlock={copy.dealer_approved.decision_block}

                                showContactBlock
                                contactInfo={this.getDealershipOrPartnerContactInfo()}
                                contactBlock={copy.dealer_approved.contact_block}

                                showAdditionalBlock={!!completeMyPaperworkStore.tradeInUrl}
                                additionalBlock={copy.dealer_approved.additional_block}

                                showDisclosureBlock
                                disclosureBlock={copy.dealer_approved.disclosure_block}

                                showCTAsBlock={!!completeMyPaperworkStore.tradeInUrl}
                                ctasBlock={{
                                    primary: {
                                        openLinkInNewTab: true,
                                        oid: copy.dealer_approved.primary_cta.oid,
                                        label: copy.dealer_approved.primary_cta.label,
                                        href: completeMyPaperworkStore.tradeInUrl,
                                    },
                                }}
                            />
                        </Container>
                    );
                }

                // Dealer Declined
                if (completeMyPaperworkStore.showApplicationDealerDeclinedView) {
                    return (
                        <Container>
                            <OffersDeclined
                                uxCopyStore={this.props.uxCopyStore}
                                contactInfo={this.getDealershipOrPartnerContactInfo()}
                                financeUrl={completeMyPaperworkStore.financeUrl}
                            />
                        </Container>
                    );
                }

                // Declined / Manual Review Application
                if (!completeMyPaperworkStore.hasAvailableOffers) {
                    return <AdditionalInformationSubmittedRequiresReview/>;
                }

                // Dealer Checklist
                return (
                    // if partner is Reveo and is inside iFrame background is white and no min height, else moon-grey
                    <div
                        className={classNames("px-4 py-3", isReveoInIframe ? "spark-bg-white" : "min-vh-100 spark-bg-moon-grey")}>
                        <Container>
                            <this.DealerChecklistTitle/>

                            {/* Tabs to toggle between Primary and Coapplicant checklist */}
                            {completeMyPaperworkStore.applicationType === APPLICATION_TYPE.JOINT && (
                                <this.ApplicantSelectionBtns/>
                            )}

                            {/* Selected Vehicle Tile: we hide the vehicle tile if the partner is Reveo */}
                            {!partnerStore.isReveo &&
                            <VehicleChecklistItem
                                vehicleConfiguration={completeMyPaperworkStore.vehicleConfiguration}
                                expandable={!!completeMyPaperworkStore.vehicleConfiguration}
                            />
                            }

                            {/* Financing */}
                            {completeMyPaperworkStore.showTransparentOffers && (
                                <YourFinancingChecklistItem
                                    openReviewOffersModal={this.openReviewOffersModal}
                                />
                            )}

                            {/* Drivers License Verification */}
                            {(completeMyPaperworkStore.showDriversLicense) && (
                                <LicenseVerificationChecklistItem/>
                            )}

                            {/* SSN Verification*/}
                            <SSNVerificationChecklistItem/>

                            {/* Address Verification */}
                            <AddressVerificationChecklistItem/>

                            {/* Phone Bill Verification*/}
                            <PhoneBillVerificationChecklistItem/>

                            {/* Income Verification */}
                            <IncomeVerificationChecklistItem/>

                            {/* Additional Information */}
                            <InfoToReviewChecklistItem
                                hasSeen={completeMyPaperworkStore.hasReviewedDocsToBring}
                                onSeen={completeMyPaperworkStore.sendDidReviewDocumentsToBring}
                                showCollapsedView={!this.props.partnerStore.isRV}
                            />

                            {/* Disclosure */}
                            <DisclosureBlock
                                showRuleLine
                                disclosureText={copy.disclosure}
                            />

                            {!!this.props.partnerStore.isRV &&
                            <BrandingValueProp
                                showPoweredByOctane
                                showDesktopLayout
                                showBrandingForCampingWorld
                            />}
                        </Container>
                    </div>
                );
            }
        }
    )
);
