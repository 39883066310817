import React from 'react';
import template from 'lodash.template';
import {Container} from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html";
import classnames from "classnames";

import {FancySpinner} from "../../components/spinners/fancy-spinner";
import {LayoutComponent} from '../../components/layout-component';
import {uploadFailed} from '../../components/svg/graphics';
import {CTAFooter} from "../../components/cta-footer";

import copy from './copy';
import {isInIFrame} from '../../utils/iframe-utils';


export function SubmittingVerification({numDocs}) {
    const s = (numDocs === 1) ? "" : "s";
    return (
        <Container>
            <div className="w-450px mw-100 mx-auto my-5 text-center d-flex justify-content-center align-items-center">
                <div className="text-center">
                    <div className="mx-auto my-4 d-inline-block">
                        <FancySpinner/>
                    </div>
                    <ReactMarkdown
                        className="h2 font-weight-bold mt-3 mb-2 pb-2"
                        source={template(copy.DOCUMENT_UPLOADING.header)({numDocs, s})}
                    />
                    <ReactMarkdown
                        className="h6 mb-4 pb-3 spark-text-secondary"
                        source={copy.DOCUMENT_UPLOADING.subtitle}
                    />
                </div>
            </div>
        </Container>
    );
}

export function VerificationSubmittedSuccess({numDocs, paperworkUrl, uploadMoreDocuments, icon, isEmbedded}) {
    const s = (numDocs === 1) ? "" : "s";
    const headline = template(copy.UPLOADING_SUCCESS.header)({numDocs, s});
    const bodyText = copy.UPLOADING_SUCCESS.subtitle;
    const ctaProps = {
        primary: {
            label: "Continue with Checklist",
            href: paperworkUrl,
        },
        secondary: {
            label: "Upload Another Document",
            onClick: uploadMoreDocuments,
        },
        /* if this is a Reveo/RV partner experience in an iFrame, show a non-sticky version of our cta */
        isSticky: !(isEmbedded && isInIFrame()),
    };

    return (
        <Container>
            <div className="w-450px mw-100 mx-auto my-5 text-center d-flex justify-content-center align-items-center">
                <div className="text-center">
                    {/* Layout with svg icon, header and body text */}
                    <LayoutComponent
                        icon={icon}
                        headline={headline}
                        bodyText={bodyText}
                    />
                    <div className="w-300px mx-auto">
                        <CTAFooter {...ctaProps} className="mt-5"/>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export function VerificationSubmittedError({numDocs, paperworkUrl, uploadOtherDocuments, isEmbedded}) {
    const s = (numDocs === 1) ? "" : "s";
    const headline = template(copy.UPLOADING_ERROR.header)({numDocs, s});
    const bodyText = copy.UPLOADING_ERROR.subtitle;
    const ctaProps = {
        primary: {
            label: "Upload Other Documents",
            onClick: uploadOtherDocuments,
        },
        secondary: {
            label: "Back to Dealer Checklist",
            href: paperworkUrl,
        },
        /* if this is a Reveo/RV partner experience in an iFrame, show a non-sticky version of our cta */
        isSticky:!(isEmbedded && isInIFrame()),
    };

    return (
        <Container>
            <div className="w-450px mw-100 mx-auto my-5 text-center d-flex justify-content-center align-items-center">
                <div className="text-center">
                    {/* Layout with svg icon, header and body text */}
                    <LayoutComponent
                        icon={uploadFailed}
                        headline={headline}
                        bodyText={bodyText}
                    />
                    <div className={classnames(isEmbedded && "w-300px mx-auto")}>
                        <CTAFooter {...ctaProps} className="mt-5"/>
                    </div>
                </div>
            </div>
        </Container>
    );
}
