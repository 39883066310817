import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown/with-html";
import {inject, observer} from 'mobx-react';

import {ExpandableChecklistItem, BUTTON_TYPES} from "./checklist-item";

import copy from './copy.json';
import styles from './checklist-item.module.scss';

export const InfoToReviewChecklistItem = inject('uxCopyStore')(observer(({uxCopyStore, hasSeen, onSeen, showCollapsedView}) => {
        const onExpand = () => {
            if (!hasSeen) {
                onSeen();
            }
        };

        // fetch copy from UXCopyStore if available
        const {title, has_seen_text, instructions} = copy.info_to_review;
        const getTitle = () => uxCopyStore.getDealerChecklistItemCopy('info_to_review.title', title)
        const getHasSeenText = () => uxCopyStore.getDealerChecklistItemCopy('info_to_review.has_seen_text', has_seen_text)
        const getInstructions = () => uxCopyStore.getDealerChecklistItemCopy('info_to_review.instructions', instructions)

        const getButtonType = () => {
            if (!hasSeen) {
                return BUTTON_TYPES.REVIEW;
            }
            return BUTTON_TYPES.DONE;
        };

        // showCollapsedView shows the bell icon and either custom or default text
        const CollapsedView = () => (hasSeen && showCollapsedView) ? (
            <div className="border-top border-secondary d-flex align-items-center mt-3 pt-2">
                <img className={styles.bell} src="/images/icon-reminder-bell.png"/>
                <span className="ml-3 spark-text-14px-bold">{getHasSeenText()}</span>
            </div>
        ) : null;

        // when the tile is expanded, show custom or default instructions
        const ExpandedView = () => (
            <ReactMarkdown
                source={getInstructions()}
                className="mt-3 spark-text-secondary"
                renderers={{
                    paragraph: props => <p className="mb-3">{props.children}</p>,
                }}
            />
        );

        return (
            <ExpandableChecklistItem
                getTitle={getTitle}
                getButtonType={getButtonType}
                ExpandedView={ExpandedView}
                CollapsedView={CollapsedView}
                onExpand={onExpand}
            />
        );
    })
);

InfoToReviewChecklistItem.propTypes = {
    hasSeen: PropTypes.bool.isRequired,
    onSeen: PropTypes.func.isRequired,
    showCollapsedView: PropTypes.bool.isRequired,
}
