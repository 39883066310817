import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {observable, runInAction, reaction} from 'mobx';
import {Redirect} from "react-router-dom";
import {Container} from 'react-bootstrap';
import {isInIFrame} from "../../utils/iframe-utils";

import {LayoutComponent} from "../../components/layout-component";
import {license, licenseUploaded} from '../../components/svg/graphics';

import {
    SubmittingVerification,
    VerificationSubmittedSuccess,
    VerificationSubmittedError} from "./verification-interstitial";
import {UploadCTAFooter} from "./upload-cta-footer";

import copy from './copy';


export const LicenseVerification = inject('completeMyPaperworkStore', 'partnerStore')(
    observer(
        class _LicenseVerification extends React.Component {
            static propTypes = {
                completeMyPaperworkStore: PropTypes.shape({
                    isInvalidApplication: PropTypes.bool.isRequired,
                    hasSubmittedAdditionalInfo: PropTypes.bool.isRequired,
                    isLicenseVerificationComplete: PropTypes.bool.isRequired,
                    isLicenseVerificationAccepted: PropTypes.bool.isRequired,

                    getPaperworkUrl: PropTypes.func.isRequired,
                    submitLicenseVerification: PropTypes.func.isRequired,
                }).isRequired,
            };

            // keep track of ui state
            uiState = observable({
                isSubmitting: false,
                filesSelected: false,
                uploadError: false,
            });

            //scroll to top when mount, might need to update in between tho
            componentDidMount() {
                window.scrollTo(0,0);
            }

            componentWillUnmount() {
                // dispose reaction
                this.disposeScrollForSubmittingChange();
            }

            //scrolls to top when the view changes
            disposeScrollForSubmittingChange = reaction(
                () => {
                    if (this.uiState.isSubmitting) {
                        return 'is-submitting';
                    }
                    if (this.uiState.filesSelected) {
                        return 'has-submitted';
                    }
                    return 'not-submitted';
                },
                (isSubmitting) => window.scroll(0,0)
            );

            /**
             * False if the application is invalid, the customer has not submitted additional info
             */
            get canViewPage() {
                const {completeMyPaperworkStore} = this.props;
                // check if this is a valid application
                if (completeMyPaperworkStore.isInvalidApplication) {
                    return false; // app was not found, has expired or there was an error
                }

                if (!completeMyPaperworkStore.hasSubmittedAdditionalInfo) {
                    return false; // app was found, but the customer has not filled in their additional info
                }

                if (completeMyPaperworkStore.isLicenseVerificationAccepted) {
                    return false; // if License verification has been accepted or was not required
                }

                return true;
            }

            submitLicenseVerification = ({files}) => {
                const {completeMyPaperworkStore} = this.props;

                // update filesSelected / isSubmitting -> true
                runInAction(() => {
                    this.uiState.isSubmitting = true;
                    this.uiState.filesSelected = files;
                });

                return completeMyPaperworkStore.submitLicenseVerification(files)
                    .then((result) => {
                        // update isSubmitting -> false
                        runInAction(() => {
                            this.uiState.isSubmitting = false;
                            this.uiState.uploadError = result?.error;
                        });
                    });
            };


            uploadMoreDocuments = () => {
                 runInAction(() => {
                    this.uiState.isSubmitting = false;
                    this.uiState.filesSelected = false;
                    this.uiState.uploadError = false;
                });
            };

            render() {
                const {completeMyPaperworkStore, partnerStore} = this.props;
                const numFilesSelected = this.uiState.filesSelected.length;

                // fallback to dealer checklist
                if (!this.canViewPage) {
                    return <Redirect to={completeMyPaperworkStore.getPaperworkUrl()}/>;
                }

                //renders if isSubmitting
                if (this.uiState.isSubmitting) {
                    return (
                        <SubmittingVerification
                            numDocs={numFilesSelected}
                        />
                    );
                }

                //renders when upload complete
                if (this.uiState.filesSelected) {
                    if (this.uiState.uploadError) {
                        return (
                            <VerificationSubmittedError
                                numDocs={numFilesSelected}
                                paperworkUrl={completeMyPaperworkStore.getPaperworkUrl()}
                                uploadOtherDocuments={this.uploadMoreDocuments}
                                isEmbedded={partnerStore.isEmbeddedPartner}
                            />
                        );
                    }
                    // if uiState finishing submitting (without errors)
                    return (
                        <VerificationSubmittedSuccess
                            icon={licenseUploaded}
                            numDocs={numFilesSelected}
                            paperworkUrl={completeMyPaperworkStore.getPaperworkUrl()}
                            uploadMoreDocuments={this.uploadMoreDocuments}
                            isEmbedded={partnerStore.isReveo || partnerStore.isRV}
                        />
                    );
                }

                const licenseVerificationCopy = (
                    !completeMyPaperworkStore.isLicenseVerificationComplete ?
                        copy.license_verification : copy.licence_verification_resubmit
                );

                return (
                    <Container className="mt-5">
                        {/* Layout with svg icon, headline and body text */}
                        <LayoutComponent
                            icon={license}
                            headline={licenseVerificationCopy.title}
                            bodyText={licenseVerificationCopy.text}
                        />

                        {/* Upload License Verification Sticky CTA Footer */}
                        <UploadCTAFooter
                            onFilesSelected={this.submitLicenseVerification}
                            secondaryHref={completeMyPaperworkStore.getPaperworkUrl()}
                            isSticky={!(partnerStore.isEmbeddedPartner && isInIFrame())}
                        />
                    </Container>
                );
            }
        }
    )
);
