export const DOCUMENT_TYPE = {
    PROOF_OF_INCOME: "proof of income",
    DRIVERS_LICENSE: "driver's license",
    SOCIAL_SECURITY_CARD: "social security card",
    PROOF_OF_ADDRESS: "proof of address",
    PHONE_BILL: "phone bill",
};

export const DOCUMENT_CATEGORY = {
    INCOME_VERIFICATION: "income verification",
    CUSTOMER_VERIFICATION: "customer verification",
};
