import React from "react";
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';

import {Svg} from "../../../components/svg";
import {ChecklistItem, BUTTON_TYPES} from "./checklist-item";

import copy from './copy.json';


export const PhoneBillVerificationChecklistItem = inject('completeMyPaperworkStore', 'historyStore')(
    observer(
        class _PhoneBillVerificationChecklistItem extends React.Component {
            static propTypes = {
                historyStore: PropTypes.shape({
                    history: PropTypes.shape({
                        push: PropTypes.func.isRequired
                    }),
                }).isRequired,
                completeMyPaperworkStore: PropTypes.shape({
                    hasSubmittedPhoneBillVerification: PropTypes.bool.isRequired,
                    isPhoneBillVerificationRequired: PropTypes.bool.isRequired,
                    isPhoneBillVerificationAccepted: PropTypes.bool.isRequired,
                    isPhoneBillVerificationComplete: PropTypes.bool.isRequired,
                    getPaperworkUrl: PropTypes.func.isRequired,
                }).isRequired,
            };

            copy = copy.phone_bill_verification;

            getSubText() {
                const {completeMyPaperworkStore} = this.props;

                if (!completeMyPaperworkStore.hasSubmittedPhoneBillVerification) {
                    return null;
                }
                if (!completeMyPaperworkStore.isPhoneBillVerificationComplete) {
                    return this.copy.processing_text;
                }
                if (!completeMyPaperworkStore.isPhoneBillVerificationAccepted) {
                    return this.copy.rejected_text;
                }
                return null;
            }

            getButtonType() {
                const {completeMyPaperworkStore} = this.props;
                if (completeMyPaperworkStore.isPhoneBillVerificationAccepted) {
                    return BUTTON_TYPES.DONE;
                }
                if (completeMyPaperworkStore.isPhoneBillVerificationComplete) {
                    return BUTTON_TYPES.RESUBMIT;
                }
                return BUTTON_TYPES.SUBMIT_DOCS;
            }

            goToPhoneBillVerification = () => {
                let cmpStore = this.props.completeMyPaperworkStore;
                let applicantUuid = cmpStore[`${cmpStore.prefix}Uuid`];
                this.props.historyStore.history.push(
                    cmpStore.getPaperworkUrl('/phone-bill-verification', applicantUuid)
                );
            };

            render() {
                const {
                    isPhoneBillVerificationRequired,
                    isPhoneBillVerificationAccepted,
                } = this.props.completeMyPaperworkStore;
                const checklistItemProps = {
                    title: this.copy.title,
                    buttonType: this.getButtonType(),
                };

                if (!isPhoneBillVerificationRequired) {
                    return null;
                }

                if (!isPhoneBillVerificationAccepted) {
                    checklistItemProps.onClickButton = this.goToPhoneBillVerification;
                }

                const subText = this.getSubText();
                return (
                    <ChecklistItem {...checklistItemProps}>
                        {subText && (
                            <div className="border-top border-secondary mt-3 pt-2">
                                <Svg className="mr-2 spark-text-note" svg="processing-icon"/>
                                <span className="spark-text-14px-bold">
                                    {subText}
                                </span>
                            </div>
                        )}
                    </ChecklistItem>
                );
            }
        }
    )
);
