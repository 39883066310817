import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {Button, Modal} from 'react-bootstrap';

import {TransparentOffers} from "../../../components/offers/transparent-offers";


export const ReviewOffersModalHeader = inject('rideOctaneModalStore', 'completeMyPaperworkStore')(
    observer(
        class _ReviewOffersModalHeader extends React.Component {
            static propTypes = {
                copy: PropTypes.shape({
                    header: PropTypes.shape({
                        close: PropTypes.string.isRequired,
                        title: PropTypes.string.isRequired,
                        subtitle: PropTypes.string.isRequired,
                    }).isRequired,
                }).isRequired,
            };

            onClickClose = () => {
                this.props.rideOctaneModalStore.closeModal();
            };

            render() {
                return (
                    <Modal.Header>
                        <Modal.Title as="div" className="text-center flex-grow-1">
                            {this.props.completeMyPaperworkStore.hasSelectedOffer && (
                                <div className="text-right">
                                    <Button variant="link" onClick={this.onClickClose}>
                                        {this.props.copy.header.close}
                                    </Button>
                                </div>
                            )}
                            <h5 className="font-weight-bold">
                                {this.props.copy.header.title}
                            </h5>
                            <p className="spark-text-secondary">
                                {this.props.copy.header.subtitle}
                            </p>
                        </Modal.Title>
                    </Modal.Header>
                );
            }
        }
    )
);

export const ReviewOffersModalContent = inject('rideOctaneModalStore', 'completeMyPaperworkStore')(
    observer(
        class _ReviewOffersModalContent extends React.Component {
            onClickChangeSelectedOffer = selectedOffer => {
                this.props.completeMyPaperworkStore
                    .sendSelectedOffer(selectedOffer)
                    .then(this.props.rideOctaneModalStore.closeModal);
            };
            render() {
                const {
                    applicationOffers,
                    applicationSelectedOffer} = this.props.completeMyPaperworkStore;
                return (
                    <TransparentOffers
                        offers={applicationOffers.loan.offers}
                        selectedOffer={applicationSelectedOffer}
                        onClickSelect={this.onClickChangeSelectedOffer}
                    />
                );
            }
        }
    )
);
