import React from 'react';
import classnames from 'classnames';
import styles from './checklist-item.module.scss';
import {Button} from 'react-bootstrap';
import copy from './copy.json';
import {observer} from 'mobx-react';
import {observable, runInAction} from 'mobx';
import PropTypes from 'prop-types';

export const BUTTON_TYPES = {
    DONE: 'DONE',
    REVIEW: 'REVIEW',
    RESUBMIT: 'RESUBMIT',
    SUBMIT_DOCS: 'SUBMIT_DOCS',
};


export const ExpandableChecklistItem = (
    observer(
        class _ExpandableChecklistItem extends React.Component {
            static propTypes = {
                getTitle: PropTypes.func,
                getButtonType: PropTypes.func,
                ExpandedView: PropTypes.elementType,
                CollapsedView: PropTypes.elementType,

                onExpand: PropTypes.func,
                onCollapse: PropTypes.func,
            };

            // keep track of ui state
            uiState = observable({
                expanded: false,
            });

            toggleExpanded = e => {
                e.preventDefault();

                // onExpand/onCollapse callbacks
                if (!this.uiState.expanded) {
                    this.props.onExpand && this.props.onExpand();
                } else {
                    this.props.onCollapse && this.props.onCollapse();
                }

                // update uiState
                runInAction(() => {
                    this.uiState.expanded = !this.uiState.expanded;
                });
            };

            render() {
                const {expanded} = this.uiState;
                const {CollapsedView, ExpandedView} = this.props;
                const title = this.props.getTitle && this.props.getTitle({expanded});
                const buttonType = this.props.getButtonType && this.props.getButtonType({expanded});

                const className = classnames(
                    styles.container,
                    "spark-bg-white rounded spark-shadow-tile p-3 my-2",
                    {[styles.isExpanded]: expanded}
                );

                return (
                    <div className={className}>
                        <div className="d-flex justify-content-between align-items-center">
                            <ChecklistItemTitleText title={title}/>
                            <ChecklistItemButton expandable buttonType={buttonType} onClick={this.toggleExpanded}/>
                        </div>
                        {this.uiState.expanded ? (
                            ExpandedView ? <ExpandedView/> : null
                        ) : (
                            CollapsedView ? <CollapsedView/> : null
                        )}
                    </div>
                );
            }
        }
    )
);

export function ChecklistItem({title, buttonType, onClickButton, children}) {
    return (
        <div className={classnames(styles.container, "spark-bg-white rounded spark-shadow-tile p-3 my-2")}>
            <div className="d-flex justify-content-between align-items-center">
                <ChecklistItemTitleText title={title}/>
                <ChecklistItemButton buttonType={buttonType} onClick={onClickButton}/>
            </div>
            {children}
        </div>
    );
}

function ChecklistItemTitleText({title}) {
    return (
        <p className="spark-text-16px-bold">
            {title}
        </p>
    );
}

export function ChecklistItemButton({expandable, buttonType, onClick}) {
    let icon = null;
    let label;
    let variant;
    let className = `font-weight-bold w-125px ${styles.btnWidth} p-2`;

    switch (buttonType) {
        case BUTTON_TYPES.DONE:
            variant = 'outline-secondary';
            className = classnames(className, "d-flex align-items-center");
            label = copy.button_text.done;
            icon = <img className={classnames(styles.btnCheckIcon, 'mr-2')} src="/images/check-mark.png"/>;
            break;

        case BUTTON_TYPES.REVIEW:
            variant = 'outline-secondary';
            className = classnames(className, "d-flex align-items-center");
            label = copy.button_text.unseen;
            icon = <div className={classnames(styles.btnDotIcon, 'mr-2 spark-bg-secondary')}/>;
            break;

        case BUTTON_TYPES.RESUBMIT:
            variant = 'primary';
            label = copy.button_text.resubmit;
            break;

        case BUTTON_TYPES.SUBMIT_DOCS:
            variant = 'primary';
            label = copy.button_text.submit_docs;
            break;

        default:
            return null;
    }

    return (
        <Button size="sm" className={className} variant={variant} onClick={onClick}>
            {icon}
            {label}
            {!!expandable && (
                <img className={classnames(styles.btnArrow, 'ml-auto')} src="/images/icon-arrow-down.png"/>
            )}
        </Button>
    );
}
