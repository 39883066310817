import React from 'react';

import {LayoutComponent} from '../../components/layout-component';
import {ReturnHomeCta, CTAFooter} from "../../components/cta-footer";
import {FancySpinner} from "../../components/spinners/fancy-spinner";
import {offersAvailable, weWillCallYou} from '../../components/svg/graphics';
import {observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import copy from './copy.json';
import {APPLICATION_TYPE} from '../../enums/applications';


export const SubmittingAdditionalInformation = inject('completeMyPaperworkStore')(
    observer(({completeMyPaperworkStore}) => {
        const {title, bodyText} = copy.submitting_interstitial;
        const text = completeMyPaperworkStore.applicationType === APPLICATION_TYPE.JOINT ? bodyText.joint_application : bodyText.individual_application;

        return (
            <div className="w-450px mw-100 mx-auto my-5 text-center">
                <div className="mx-auto my-4 d-inline-block">
                    <FancySpinner/>
                </div>
                <h2 className="my-4 font-weight-bold">
                    {title}
                </h2>
                <p className="my-4">
                    {text}
                </p>
            </div>
        );
    })
);

export function AdditionalInformationSubmittedSuccess({paperworkUrl, isSticky}) {
    const {title, text, cta} = copy.information_submitted_success_interstitial;

    return (
        <div className="mt-5">
            {/* Layout with svg icon, headline and body text */}
            <LayoutComponent
                icon={offersAvailable}
                headline={title}
                bodyText={text}
            />
            <CTAFooter
                isSticky={isSticky}
                primary={{
                    label: cta,
                    href: paperworkUrl,
                }}
            />
        </div>
    );
}


export const AdditionalInformationSubmittedRequiresReview = inject('uxCopyStore', 'completeMyPaperworkStore')(
    observer(
        class _AdditionalInformationSubmittedRequiresReview extends React.Component {

            uiState = observable({
                isInIframe: false,
                isLoaded: false,
            });

            componentDidMount() {
                this.props.completeMyPaperworkStore.sendPostMessage();
            }

            render() {
                const default_copy = copy.information_submitted_requires_review_interstitial;
                const title = this.props.uxCopyStore.getCompleteMyPaperworkManualReviewCopy('title', default_copy.title);
                const text = this.props.uxCopyStore.getCompleteMyPaperworkManualReviewCopy('text', default_copy.text);
                return (
                    <div className="mt-5">
                        {/* Layout with svg icon, headline and body text */}
                        <LayoutComponent
                            icon={weWillCallYou}
                            headline={title}
                            bodyText={text}
                        />
                        <ReturnHomeCta/>
                    </div>
                );
            }
        }
    )
);
